import React from "react";

const Paginator = ({
  pageNumber,
  pageSize,
  totalCount,
  onClick,
  className = null,
}) => {
  const pagesNumbers = [];
  const totalPagesCount = Math.ceil(totalCount / pageSize);
  const minPageNumber = pageNumber >= 5 ? pageNumber - 4 : 1;
  const maxPageNumber =
    pageNumber + 4 <= totalPagesCount ? pageNumber + 4 : totalPagesCount;
  for (let i = minPageNumber; i <= maxPageNumber; i++) {
    pagesNumbers.push(i);
  }
  const buttons = pagesNumbers.map((n, index) => (
    <button
      type="button"
      className={`mx-1 btn btn-sm ${
        pageNumber === n ? "btn-info btn-fill" : ""
      }`}
      key={n}
      onClick={() => onClick(n)}
    >
      {n}
    </button>
  ));

  return <div className={className}>{buttons.length > 1 ? buttons : null}</div>;
};

export default Paginator;
