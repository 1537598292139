export const actions = {
  SET_STATISTIC_IS_LOADING: "SET_STATISTIC_IS_LOADING",
  SET_STATISTIC_FILTERS: "SET_STATISTIC_FILTERS",
  SET_STATISTIC_FILE_CREATE_IN_PROCESS: "SET_STATISTIC_FILE_CREATE_IN_PROCESS",
};
export const setStatisticIsLoading = (isLoading) => {
  return {
    type: actions.SET_STATISTIC_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setStatisticFilters = (data) => {
  return {
    type: actions.SET_STATISTIC_FILTERS,
    data: data,
  };
};
export const setStatisticFileCreateInProcess = (inProcess) => {
  return {
    type: actions.SET_STATISTIC_FILE_CREATE_IN_PROCESS,
    inProcess: inProcess,
  };
};
