import { mdiPlusBoxOutline, mdiCheckAll, mdiPencil, mdiTrashCan } from "@mdi/js";
import Icon from "@mdi/react";
import React from "react";
import { Table, Row, Col, Container, Card, Modal } from "react-bootstrap";
import Paginator from "../../../components/Pagination/Paginator";

const DeleteModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Удаление дирекции</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Объект будет удален
        <br />
        <b>Продолжить?</b>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          disabled={props.saveInProcess}
          onClick={props.handleClose}
        >
          {props.saveInProcess ? (
            <div className="spinner-border spinner-border-sm text-white" />
          ) : (
            "Не удалять"
          )}
        </button>
        <button
          className="btn-fill btn btn-sm btn-danger"
          disabled={props.saveInProcess}
          onClick={props.onSave}
        >
          {props.saveInProcess ? (
            <div className="spinner-border spinner-border-sm text-white" />
          ) : (
            "Удалить"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const ManageModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Редактирование дирекции</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={props.handleSubmit(props.onSubmit)}>
          <Row>
            <Col md={3}>Наименование</Col>
            <Col md={9}>
              <input
                type="text"
                className="form-control form-control-sm"
                placeholder="Наименование объекта"
                {...props.register("name")}
              />
            </Col>
          </Row>
          <button
            className="btn-fill btn btn-sm btn-info"
            disabled={props.saveInProcess}
          >
            {props.saveInProcess ? (
              <div className="spinner-border spinner-border-sm text-white" />
            ) : (
              "Сохранить"
            )}
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};
const DirectorateTableRow = (props) => {
  return (
    <tr>
      <td><small>{props.number}</small></td>
      <td><small>{props.name}</small></td>
      <td>
        <div className="d-flex justify-content-start">
          <button
            className="btn-fill btn btn-sm btn-info mr-2"
            onClick={(e) => {
              e.preventDefault();
              props.setId(props.id);
              props.setShowManageModal(true);
            }}
          >
            <Icon path={mdiPencil} size={1} />
          </button>
          <button
            className="btn-fill btn btn-sm btn-danger"
            onClick={(e) => {
              e.preventDefault();
              props.setId(props.id);
              props.setShowDeleteModal(true);
            }}
          >
            <Icon path={mdiTrashCan} size={1} />
          </button>
        </div>
      </td>
    </tr>
  );
};
const DirectorateList = (props) => {
  const rows = props.directorates.map((d, index) => (
    <DirectorateTableRow
      key={d.id}
      setShowManageModal={props.setShowManageModal}
      setShowDeleteModal={props.setShowDeleteModal}
      setId={props.setId}
      number={index + 1}
      {...d}
    />
  ));
  const displayCountOptions = [
    { value: 5, label: "5 элементов" },
    { value: 10, label: "10 элементов" },
    { value: 15, label: "15 элементов" },
    { value: 20, label: "20 элементов" },
    { value: 25, label: "25 элементов" },
  ].map((e, index) => (
    <option value={e.value} key={index}>
      {e.label}
    </option>
  ));
  return (
    <Container fluid>
      <ManageModal
        show={props.showManageModal}
        register={props.register}
        handleSubmit={props.handleSubmit}
        onSubmit={props.onSubmit}
        handleClose={() => {
          props.setId(null);
          props.setShowManageModal(false);
        }}
      />
      <DeleteModal
        show={props.showDeleteModal}
        onSave={props.handleObjectDelete}
        handleClose={() => {
          props.setId(null);
          props.setShowDeleteModal(false);
        }}
      />
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header className="d-flex justify-content-between">
              <div>
                <Card.Title as="h4">Дирекции</Card.Title>
                <button
                  className="btn-fill btn btn-sm btn-info mt-2"
                  onClick={() => {
                    props.setId(null);
                    props.setShowManageModal(true);
                  }}
                >
                  Добавление дирекции
                  <Icon path={mdiPlusBoxOutline} size={1} className="ml-2" />
                </button>
              </div>
              <div>
                <select
                  className="form-control form-control-sm"
                  name="pageSize"
                  value={props.count}
                  placeholder="Кол-во элементов"
                  onChange={props.handleCountChange}
                >
                  {displayCountOptions}
                </select>
                <Paginator
                  className="mt-2 text-lg-right"
                  pageSize={props.count}
                  pageNumber={props.number}
                  totalCount={props.totalCount}
                  onClick={props.handleNumberChange}
                />
              </div>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0">
              <form onSubmit={props.filtersApply(props.handleFiltersApply)}>
                <Table className="table-hover table-sm">
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Наименование</th>
                      <th></th>
                    </tr>
                    <tr>
                      <th colSpan={2}>
                        <input
                          type="text"
                          className="form-control form-control-sm"
                          {...props.filtersRegister("name")}
                        />
                      </th>
                      <th>
                        <div className="d-flex justify-content-start">
                          <button className="btn-fill btn btn-info btn-sm">
                            <Icon path={mdiCheckAll} size={1} />
                          </button>
                          <button
                            className="btn-fill btn btn-danger btn-sm ml-2"
                            type="button"
                            onClick={props.handleFiltersReset}
                          >
                            <Icon path={mdiTrashCan} size={1} />
                          </button>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>{rows}</tbody>
                </Table>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default DirectorateList;
