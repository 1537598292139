import React from "react";
import { connect } from "react-redux";
import {
  getDirectorates,
  getBranches,
  saveObject,
  deleteObject,
  importMetaFile,
} from "redux/thunk/MetaThunk";
import BranchList from "./BranchList";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const BranchListContainer = (props) => {
  const [directorates, setDirectorates] = useState([]);
  const [branches, setBranches] = useState([]);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    page_number: 1,
    page_size: 20,
  });
  const {
    register: filtersRegister,
    handleSubmit: filtersApply,
    reset: filtersReset,
  } = useForm({
    defaultValues: { name: "", directorate: "" },
  });
  const [id, setId] = useState(null);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { name: "", short_name: "", directorate: "" },
  });
  const onSubmit = (data) => {
    props.saveObject(data, !id, "branches").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowManageModal(false);
        props.getBranches(filters).then((res) => {
          setBranches(res && res.status === 200 ? res.data?.branches : []);
          setCount(res && res.status === 200 ? res.data?.count : []);
        });
      }
    });
  };
  const handleCountChange = (e) => {
    setFilters({ ...filters, page_size: Number(e.target.value) });
    props
      .getBranches({ ...filters, page_size: Number(e.target.value) })
      .then((res) => {
        setBranches(res && res.status === 200 ? res.data?.branches : []);
        setCount(res && res.status === 200 ? res.data?.count : []);
      });
  };
  const handleNumberChange = (number) => {
    setFilters({ ...filters, page_number: number });
    props.getBranches({ ...filters, page_number: number }).then((res) => {
      setBranches(res && res.status === 200 ? res.data?.branches : []);
      setCount(res && res.status === 200 ? res.data?.count : []);
    });
  };
  const handleFiltersApply = (data) => {
    setFilters({ ...filters, ...data });
    props.getBranches({ ...filters, ...data }).then((res) => {
      setBranches(res && res.status === 200 ? res.data?.branches : []);
      setCount(res && res.status === 200 ? res.data?.count : []);
    });
  };
  const handleFiltersReset = () => {
    setFilters({ page_number: 1, page_size: 20 });
    filtersReset({ page_number: 1, page_size: 20 });
    props.getBranches({ page_number: 1, page_size: 20 }).then((res) => {
      setBranches(res && res.status === 200 ? res.data?.branches : []);
      setCount(res && res.status === 200 ? res.data?.count : []);
    });
  };
  const handleFileAttached = (e) => {
    props.importMetaFile(e.target.files[0], "branches").then((res) => {
      props.getBranches().then((res) => {
        setBranches(res && res.status === 200 ? res.data.branches : []);
        setCount(res && res.status === 200 ? res.data?.count : []);
      });
    });
  };
  const handleObjectDelete = () => {
    props.deleteObject(id, "branches").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowDeleteModal(false);
        props.getBranches(filters).then((res) => {
          setBranches(res && res.status === 200 ? res.data?.branches : []);
          setCount(res && res.status === 200 ? res.data?.count : []);
        });
      }
    });
  };
  useEffect(() => {
    props
      .getDirectorates()
      .then((res) =>
        setDirectorates(res && res.status === 200 ? res.data.directorates : [])
      );
    props.getBranches(filters).then((res) => {
      setBranches(res && res.status === 200 ? res.data?.branches : []);
      setCount(res && res.status === 200 ? res.data?.count : []);
    });
  }, []);
  useEffect(() => {
    reset(
      branches.find((b) => b.id === id) || {
        name: "",
        short_name: "",
        directorate: "",
      }
    );
  }, [showManageModal]);
  return (
    <BranchList
      reset={reset}
      handleSubmit={handleSubmit}
      register={register}
      onSubmit={onSubmit}
      setShowManageModal={setShowManageModal}
      setShowDeleteModal={setShowDeleteModal}
      setId={setId}
      showManageModal={showManageModal}
      showDeleteModal={showDeleteModal}
      handleObjectDelete={handleObjectDelete}
      directorates={directorates}
      branches={branches}
      handleCountChange={handleCountChange}
      handleNumberChange={handleNumberChange}
      handleFiltersApply={handleFiltersApply}
      handleFiltersReset={handleFiltersReset}
      handleFileAttached={handleFileAttached}
      filtersRegister={filtersRegister}
      filtersApply={filtersApply}
      count={filters.page_size}
      number={filters.page_number}
      totalCount={count}
      fileOperationInProcess={props.fileOperationInProcess}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    fileOperationInProcess: state.meta.fileOperationInProcess,
  };
};
export default connect(mapStateToProps, {
  getDirectorates,
  getBranches,
  saveObject,
  deleteObject,
  importMetaFile,
})(BranchListContainer);
