import Spinner from "components/Spinners/Spinner";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getEventLogs } from "redux/thunk/MetaThunk";
import EquipmentLogList from "./EquipmentLogList";

const EquipmentLogListContainer = (props) => {
  const params = useParams();
  const [logs, setLogs] = useState([]);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({ obj_id: params.id });
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit } = useForm({
    defaultValues: { name: "", action_type: "", user: "", create: "" },
  });

  const onSubmit = (data) => {};

  useEffect(() => {
    setLoading(true);
    props.getEventLogs(filters).then((res) => {
      if (res && res.data) {
        setLogs(res.data.logs);
        setCount(res.data.count);
      }
      setLoading(false);
    });
  }, []);
  if (loading) {
    <Spinner />;
  }
  return (
    <EquipmentLogList
      logs={logs}
      register={register}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
    />
  );
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  getEventLogs,
})(EquipmentLogListContainer);
