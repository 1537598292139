import api from "../../utils/api";
import {
  setStatisticFileCreateInProcess,
  setStatisticIsLoading,
} from "../actions/StatisticActions";
import { handleErrors } from "./HandleErrors";

export const getStatistic = (filters = null) => {
  return async (dispatch, getState) => {
    const state = getState().statistic;
    dispatch(setStatisticIsLoading(true));
    try {
      const response = await api.get("v1/statistic", {
        params: filters ? filters : state.filters,
      });
      dispatch(setStatisticIsLoading(false));
      return response;
    } catch (e) {
      dispatch(setStatisticIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getStatisticFile = (filters = null) => {
  return async (dispatch, getState) => {
    const state = getState().statistic;
    dispatch(setStatisticFileCreateInProcess(true));
    try {
      const response = await api.get("v1/statistic/export", {
        params: filters ? filters : state.filters,
        responseType: 'blob'
      });
      dispatch(setStatisticFileCreateInProcess(false));
      return response;
    } catch (e) {
      dispatch(setStatisticFileCreateInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
