import Icon from "@mdi/react";
import {
  mdiExpansionCardVariant,
  mdiChartAreaspline,
  mdiOrderBoolDescendingVariant,
  mdiCogOutline,
} from "@mdi/js";

const dashboardRoutes = [
  {
    path: "/statistic",
    name: "Дашборд",
    icon: <Icon path={mdiChartAreaspline} size={1} className="mr-2" />,
    layout: "",
  },
  {
    path: "/equipments",
    name: "Оборудование",
    icon: <Icon path={mdiExpansionCardVariant} size={1} className="mr-2" />,
    layout: "",
  },
  {
    path: "/orders",
    name: "Перечни ТП",
    icon: (
      <Icon path={mdiOrderBoolDescendingVariant} size={1} className="mr-2" />
    ),
    layout: "",
  },
  {
    path: "/configure",
    name: "Администрирование",
    icon: <Icon path={mdiCogOutline} size={1} className="mr-2" />,
    layout: "",
  },
];

export default dashboardRoutes;
