import { actions } from "../actions/RootActions";

const initialState = {
  userData: {},
  notifications: [],
  isAuthenticated: false,
  authInProcess: false,
  userDataIsLoading: false,
};
export const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_USER_DATA:
      return {
        ...state,
        userData: action.data,
        isAuthenticated: action.isAuthenticated,
      };
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        notifications: action.add
          ? [
              ...state.notifications,
              {
                id: state.notifications.length + 1,
                title: action.title,
                body: action.body,
                level: action.level,
                icon: action.icon,
              },
            ]
          : state.notifications.filter((t) => t.id !== action.id),
      };
    case actions.SET_AUTH_IN_PROGRESS:
      return {
        ...state,
        authInProcess: action.inProgress,
      };
    default:
      return state;
  }
};
