import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Paginator from "../../../components/Pagination/Paginator";
import Icon from "@mdi/react";
import { mdiPlusBoxOutline, mdiCheckAll, mdiTrashCan } from "@mdi/js";

const OrderTableRow = (props) => {
  return (
    <tr>
      <td>
        <small>{props.num}</small>
      </td>
      <td>
        <small>{props.number}</small>
      </td>
      <td>
        <small>
          {Object.keys(props.directorate).length > 0
            ? props.directorate.name
            : "Не указано"}
        </small>
      </td>
      <td>
        <small>{props.starts_affect}</small>
      </td>
      <td>
        <div>
          <small>Проектов установлено: {props.non_projects_count}</small>
        </div>
        <div>
          <small>
            Производителей установлено: {props.non_manufacturers_count}
          </small>
        </div>
        <div>
          <small>Позиций проверено: {props.non_checked_count}</small>
        </div>
      </td>
      <td>
        <NavLink
          to={`/orders/${props.id}/detail`}
          className="btn-fill btn btn-sm btn-info"
        >
          Детально
        </NavLink>
      </td>
    </tr>
  );
};
const OrderList = (props) => {
  const orders = props.orders.map((o, index) => (
    <OrderTableRow key={o.id} num={index + 1} {...o} />
  ));
  const displayCountOptions = [
    { value: 5, label: "5 элементов" },
    { value: 10, label: "10 элементов" },
    { value: 15, label: "15 элементов" },
    { value: 20, label: "20 элементов" },
    { value: 25, label: "25 элементов" },
  ].map((e, index) => (
    <option value={e.value} key={index}>
      {e.label}
    </option>
  ));
  return (
    <>
      <Container fluid>
        <Row>
          <Col className="sm-12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div>
                  <Card.Title as="h4">Перечни технической поддержки</Card.Title>
                  <NavLink
                    to="/orders/create"
                    className="btn-fill btn btn-sm btn-info mt-2"
                  >
                    Добавление перечня
                    <Icon path={mdiPlusBoxOutline} size={1} className="ml-2" />
                  </NavLink>
                </div>

                <div>
                  <select
                    className="form-control form-control-sm"
                    name="pageSize"
                    value={props.count}
                    placeholder="Кол-во элементов"
                    onChange={props.handleCountChange}
                  >
                    {displayCountOptions}
                  </select>
                  <Paginator
                    className="mt-2 text-lg-right"
                    pageSize={props.count}
                    pageNumber={props.number}
                    totalCount={props.totalCount}
                    onClick={props.handleNumberChange}
                  />
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <form onSubmit={props.handleSubmit(props.onSubmit)}>
                  <Table className="table-hover table-sm">
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>Номер</th>
                        <th>Дирекция</th>
                        <th>Дата начала действия</th>
                        <th>Мета статистика</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <input
                            type="text"
                            placeholder="Номер"
                            className="form-control form-control-sm"
                            {...props.register("number")}
                          />
                        </td>
                        <td colSpan={3}>
                          <input
                            type="text"
                            placeholder="Дирекция"
                            className="form-control form-control-sm"
                            {...props.register("directorate")}
                          />
                        </td>
                        <td>
                          <div className="d-flex justify-content-start">
                            <button className="btn-fill btn btn-info btn-sm">
                              <Icon path={mdiCheckAll} size={1} />
                            </button>
                            <button
                              className="btn-fill btn btn-danger btn-sm ml-2"
                              type="button"
                              onClick={props.reset}
                            >
                              <Icon path={mdiTrashCan} size={1} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>{orders}</tbody>
                  </Table>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderList;
