/*!

=========================================================
* Light Bootstrap Dashboard React - v2.0.1
=========================================================

* Product Page: https://www.creative-tim.com/product/light-bootstrap-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/light-bootstrap-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Routes } from "react-router-dom";

import Navbar from "components/Navbars/Navbar";
import Sidebar from "components/Sidebar/Sidebar";
import EquipmentListContainer from "../views/equipment/list/EquipmentListContainer";
import EquipmentDetailContainer from "../views/equipment/detail/EquipmentDetailContainer";

import routes from "routes.js";

import sidebarImage from "assets/img/sidebar-rr.jpg";
import EquipmentManageContainer from "../views/equipment/manage/EquipmentManageContainer";
import NotificationToast from "../components/Toasts/NotificationToast";
import { Container, ToastContainer, Toast } from "react-bootstrap";
import OrderListContainer from "../views/order/list/OrderListContainer";
import OrderDetailContainer from "../views/order/detail/OrderDetailContainer";
import OrderManageContainer from "../views/order/manage/OrderManageContainer";
import StatisticContainer from "../views/statistic/StatisticContainer";
import DirectorateListContainer from "views/admin/directorate/DirectorateListContainer";
import BranchListContainer from "views/admin/branch/BranchListContainer";
import ManufacturerListContainer from "views/admin/manufacturer/ManufacturerListContainer";
import CategoryListContainer from "views/admin/category/CategoryListContainer";
import KeywordListContainer from "views/admin/keyword/KeywordListContainer";
import Admin from "views/admin/Admin";
import ProjectListContainer from "views/admin/project/ProjectListContainer";
import EquipmentLogListContainer from "views/equipment/log/EquipmentLogListContainer";
import NotFoundPage from "views/shared/404";

const App = (props) => {
  const [image, setImage] = React.useState(sidebarImage);
  const [color, setColor] = React.useState("black");
  const [hasImage, setHasImage] = React.useState(true);
  const mainPanel = React.useRef(null);
  const [showSidebar, setShowSidebar] = React.useState(false);
  const notifications = props.notifications
    ? props.notifications.map((t, index) => (
        <NotificationToast key={index} onClose={props.setNotification} {...t} />
      ))
    : [];

  return (
    <>
      <div className="wrapper">
        {showSidebar ? (
          <Sidebar
            color={color}
            image={hasImage ? image : ""}
            routes={routes}
          />
        ) : null}
        <div className={showSidebar ? "main-panel bg-white" : "content"} ref={mainPanel}>
          <Navbar
            showSidebar={showSidebar}
            setShowSidebar={setShowSidebar}
            fullName={props.userData?.full_name}
            userDataIsLoading={props.userDataIsLoading}
            logout={props.logout}
          />

          <div className={`content ${!showSidebar ? "px-3 py-2 mt-4": ""}`}>
            <div className="d-flex justify-content-end align-items-center">
              <div
                style={{
                  position: "absolute",
                  top: 40,
                  right: 15,
                  zIndex: 9999,
                }}
              >
                {notifications.length > 0 ? notifications : null}
              </div>
            </div>

            <Routes>
              <Route path="/equipments" element={<EquipmentListContainer />} />
              <Route
                path="/equipments/:id/detail"
                element={<EquipmentDetailContainer />}
              />
              <Route
                path="/equipments/:id/update"
                element={<EquipmentManageContainer />}
              />
              <Route
                path="/equipments/create"
                element={<EquipmentManageContainer />}
              />
              <Route
                path="/equipments/:id/history"
                element={<EquipmentLogListContainer />}
              />
              <Route path="/orders" element={<OrderListContainer />} />
              <Route
                path="/orders/:id/detail"
                element={<OrderDetailContainer />}
              />
              <Route path="/orders/create" element={<OrderManageContainer />} />
              <Route
                path="/orders/:id/update"
                element={<OrderManageContainer />}
              />
              <Route path="/statistic" element={<StatisticContainer />} />
              <Route path="/configure" element={<Admin />} />
              <Route
                path="/configure/directorates"
                element={<DirectorateListContainer />}
              />
              <Route
                path="/configure/branches"
                element={<BranchListContainer />}
              />
              <Route
                path="/configure/manufacturers"
                element={<ManufacturerListContainer />}
              />
              <Route
                path="/configure/categories"
                element={<CategoryListContainer />}
              />
              <Route
                path="/configure/keywords"
                element={<KeywordListContainer />}
              />
              <Route
                path="/configure/projects"
                element={<ProjectListContainer />}
              />
              <Route path="*" element={<NotFoundPage />} />
            </Routes>
          </div>
        </div>
      </div>
    </>
  );
};

export default App;
