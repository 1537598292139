import React, { useEffect, useState } from "react";
import OrderDetail from "./OrderDetail";
import { useParams } from "react-router-dom";
import {
  bulkObjsUpdate,
  checkAllOrderItems,
  copyOrder,
  deleteOrderItem,
  getOrder,
  getOrderFile,
  getOrderItems,
  getOrders,
  getOrdersDifferenceFile,
  getOrderDifference,
  saveOrderItem,
  setOrderFile,
  setOrderItemCategory,
  setOrderItemChecked,
  setOrderItemManufacturer,
  setOrderItemProject,
} from "../../../redux/thunk/OrderThunk";
import { connect } from "react-redux";
import {
  getBranches,
  getCategories,
  getManufacturers,
  getProjects,
} from "../../../redux/thunk/MetaThunk";
import { useForm } from "react-hook-form";
import { setOrderItemsFilters } from "../../../redux/actions/OrderActions";
import fileDownload from "js-file-download";
import { setNotification } from "../../../redux/actions/RootActions";
import { getEquipments } from "../../../redux/thunk/EquipmentThunk";

const OrderDetailContainer = (props) => {
  const [order, setOrder] = useState({});
  const [orders, setOrders] = useState([]);
  const [orderItems, setOrderItems] = useState([]);
  const [orderItemsCount, setOrderItemsCount] = useState(0);
  const [manufacturers, setManufacturers] = useState([]);
  const [categories, setCategories] = useState([]);
  const [branches, setBranches] = useState([]);
  const [projects, setProjects] = useState([]);
  const [showManufacturerModal, setShowManufacturerModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showProjectModal, setShowProjectModal] = useState(false);
  const [showBulkActionModal, setShowBulkActionModal] = useState(false);
  const [showOrderCopyModal, setShowOrderCopyModal] = useState(false);
  const [showAlertModal, setShowAlertModal] = useState(false);
  const [showFileDownloadModal, setShowFileDownloadModal] = useState(false);
  const [showFileUploadModal, setShowFileUploadModal] = useState(false);
  const [showOrderDifferenceModal, setShowOrderDifferenceModal] =
    useState(false);
  const [showOrderItemManageModal, setShowOrderItemManageModal] =
    useState(false);
  const params = useParams();
  const filters = { ...props.filters };
  const [checkedItems, setCheckedItems] = useState([]);

  const { register, handleSubmit, reset, control, watch } = useForm({
    defaultValues: filters,
  });

  // Хандлер отправки формы фильтрации
  const onSubmit = (data) => {
    props.setOrderItemsFilters({
      ...filters,
      ...data,
      page_number: 1,
    });
    props.getOrderItems(params.id).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(res.data.items);
        setOrderItemsCount(res.data.count);
      }
    });
  };

  // Хандлер изменения количества отображаемых объектов на странице
  const handleCountChange = (e) => {
    props.setOrderItemsFilters({
      ...filters,
      page_size: Number(e.target.value),
    });
    props.getOrderItems(params.id).then((res) => {
      setOrderItems(res.data.items);
      setOrderItemsCount(res.data.count);
    });
  };

  // Хандлер изменения номера текущей страницы
  const handleNumberChange = (number) => {
    props.setOrderItemsFilters({
      ...filters,
      page_number: number,
    });
    props.getOrderItems(params.id).then((res) => {
      setOrderItems(res.data.items);
      setOrderItemsCount(res.data.count);
    });
  };

  // Хандлер сброса фильтров
  const handleFiltersReset = () => {
    props.setOrderItemsFilters({
      page_number: 1,
      page_size: 100,
    });
    reset({});
    props.getOrderItems(params.id).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(res.data.items);
        setOrderItemsCount(res.data.count);
      }
    });
  };

  // Хандлер установки производителя позиции
  const handleManufacturerUpdate = (id, manufacturerId) => {
    props.setOrderItemManufacturer(id, manufacturerId).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(
          orderItems.map((i) =>
            i.equipment.id === res.data.id
              ? {
                  ...i,
                  manufacturer: res.data.manufacturer_data,
                  equipment: {
                    ...i.equipment,
                    manufacturer_needs_check: res.data.manufacturer_needs_check,
                  },
                }
              : i
          )
        );
        props
          .getOrder(params.id)
          .then((res) => setOrder(res && res.status === 200 ? res.data : {}));
      }
    });
  };

  // Хандлер установки категории позиции
  const handleCategoryUpdate = (id, categoryNumber) => {
    props.setOrderItemCategory(id, categoryNumber).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(
          orderItems.map((i) =>
            i.id === res.data.id
              ? {
                  ...i,
                  cat_number: res.data.cat_number,
                  price_without_tax: res.data.price_without_tax,
                  tax_value: res.data.tax_value,
                  price_with_tax: res.data.price_with_tax,
                }
              : i
          )
        );
        props
          .getOrder(params.id)
          .then((res) => setOrder(res && res.status === 200 ? res.data : {}));
      }
    });
  };

  // Хандлер установки проекта позиции
  const handleProjectUpdate = (id, projectId) => {
    props.setOrderItemProject(id, projectId).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(
          orderItems.map((i) =>
            i.id === res.data.id ? { ...i, project: res.data.project_data, root_project: res.data.root_project_data } : i
          )
        );
        props
          .getOrder(params.id)
          .then((res) => setOrder(res && res.status === 200 ? res.data : {}));
      }
    });
  };

  // Хадлер отметки позиции как проверенной
  const handleOrderItemChecked = (id) => {
    props.setOrderItemChecked(id).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(
          orderItems.map((i) =>
            i.equipment.id === res.data.id
              ? {
                  ...i,
                  equipment: {
                    ...i.equipment,
                    manufacturer_needs_check: res.data.manufacturer_needs_check,
                  },
                }
              : i
          )
        );
      }
    });
  };

  // Хандлер загрузки файла переченя ТП
  const handleFileDownload = () => {
    props.getOrderFile(params.id).then((res) => {
      if (res && res.status === 200) {
        fileDownload(res.data, order.file);
      }
      setShowFileDownloadModal(false);
    });
  };

  // Хандлер прикрепления файла к перечню ТП
  const handleFileUpload = (file, hasManufacturers, hasProjects) => {
    props
      .setOrderFile(params.id, file, hasManufacturers, hasProjects)
      .then((res) => {
        props
          .getOrder(params.id)
          .then((res) => setOrder(res && res.status === 200 ? res.data : {}));
        props.setOrderItemsFilters({
          page_number: 1,
          page_size: 100,
        });
        props.getOrderItems(params.id).then((res) => {
          if (res && res.status === 200) {
            setOrderItems(res.data.items);
            setOrderItemsCount(res.data.count);
          }
        });
        setShowFileUploadModal(false);
      });
  };

  // Хандлер массовых действий с позициям
  const handleBulkObjUpdate = (data) => {
    const queryParams = { ...data };
    queryParams.orderId = params.id;

    props.bulkObjsUpdate(queryParams).then((res) => {
      if (res && res.status === 200) {
        props.getOrderItems(params.id).then((res) => {
          setOrderItems(res.data.items);
          setOrderItemsCount(res.data.count);
          setShowBulkActionModal(false);
        });
      }
    });
  };

  // Хандлер копирования перечня ТП
  const handleOrderCopy = (sourceId) => {
    props.copyOrder(sourceId, params.id).then((res) =>
      props.getOrderItems(params.id).then((res) => {
        setOrderItems(res.data.items);
        setOrderItemsCount(res.data.count);
        setShowOrderCopyModal(false);
      })
    );
  };

  // Хандлер загрузки файла разницы выбранных перечней ТП
  const handleOrderDifferenceClick = (targetOrderId) => {
    props.getOrdersDifferenceFile(params.id, targetOrderId).then((res) => {
      if (res && res.status === 200) {
        fileDownload(res.data, "Различия_перечней.xlsx");
      }
      setShowOrderDifferenceModal(false);
    });
  };

  // Хандлер установки отметки "Проверено" для всех позиций в перечне
  const handleCheckAllOrderItems = () => {
    props.checkAllOrderItems(params.id).then((res) =>
      props.getOrderItems(params.id).then((res) => {
        if (res && res.status === 200) {
          setOrderItems(res.data.items);
          setOrderItemsCount(res.data.count);
        }
        setShowAlertModal(false);
      })
    );
  };

  // Хандлер сохранения позиции
  const handleOrderItemSave = (data, create) => {
    const formData = { ...data };
    formData.order = params.id;
    props.saveOrderItem(formData, create).then((res) => {
      props.getOrderItems(params.id).then((res) => {
        if (res && res.status === 200) {
          setOrderItems(res.data.items);
          setOrderItemsCount(res.data.count);
          setShowOrderItemManageModal(false);
        }
      });
    });
  };

  // Хандлер удаления позиции
  const handleOrderItemDelete = (id) => {
    props.deleteOrderItem(id).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(
          orderItems.map((i) =>
            i.id === res.data.id
              ? { ...i, action_type: res.data.action_type }
              : i
          )
        );
      }
    });
  };

  // Хандлер отметки строки
  const handleOrderItemMark = (id, checked) => {
    checked
      ? setCheckedItems([...checkedItems, id])
      : setCheckedItems(checkedItems.filter((i) => i !== id));

    setOrderItems(
      orderItems.map((i) => (i.id === id ? { ...i, checked: checked } : i))
    );
  };

  useEffect(() => {
    props.getOrder(params.id).then((res) => {
      setOrder(res && res.status === 200 ? res.data : {});
      props
        .getOrders({
          directorate_id: res?.data?.directorate?.id,
        })
        .then((res) =>
          setOrders(res && res.status === 200 ? res.data.orders : [])
        );
    });
    props.getOrderItems(params.id).then((res) => {
      if (res && res.status === 200) {
        setOrderItems(res.data.items);
        setOrderItemsCount(res.data.count);
      }
    });
    props
      .getManufacturers()
      .then((res) =>
        setManufacturers(
          res && res.status === 200 ? res.data.manufacturers : []
        )
      );
    props
      .getCategories()
      .then((res) =>
        setCategories(res && res.status === 200 ? res.data.categories : [])
      );
    props
      .getBranches()
      .then((res) =>
        setBranches(res && res.status === 200 ? res.data.branches : [])
      );
    props
      .getProjects()
      .then((res) =>
        setProjects(res && res.status === 200 ? res.data.projects : [])
      );
  }, []);

  return (
    <OrderDetail
      order={order}
      orderItems={orderItems}
      manufacturers={manufacturers}
      categories={categories}
      branches={branches}
      projects={projects}
      orders={orders}
      checkedItems={checkedItems}
      itemsIsLoading={props.itemsIsLoading}
      bulkActionInProcess={props.bulkActionInProcess}
      fileOperationInProcess={props.fileOperationInProcess}
      calculateDifferenceInProcess={props.calculateDifferenceInProcess}
      isLoading={props.isLoading}
      count={filters.page_size}
      number={filters.page_number}
      totalCount={orderItemsCount}
      showManufacturerModal={showManufacturerModal}
      setShowManufacturerModal={setShowManufacturerModal}
      showCategoryModal={showCategoryModal}
      setShowCategoryModal={setShowCategoryModal}
      showProjectModal={showProjectModal}
      setShowProjectModal={setShowProjectModal}
      showBulkActionModal={showBulkActionModal}
      setShowBulkActionModal={setShowBulkActionModal}
      showOrderCopyModal={showOrderCopyModal}
      setShowOrderCopyModal={setShowOrderCopyModal}
      showAlertModal={showAlertModal}
      setShowAlertModal={setShowAlertModal}
      showFileDownloadModal={showFileDownloadModal}
      setShowFileDownloadModal={setShowFileDownloadModal}
      showFileUploadModal={showFileUploadModal}
      setShowFileUploadModal={setShowFileUploadModal}
      showOrderDifferenceModal={showOrderDifferenceModal}
      setShowOrderDifferenceModal={setShowOrderDifferenceModal}
      showOrderItemManageModal={showOrderItemManageModal}
      setShowOrderItemManageModal={setShowOrderItemManageModal}
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      handleFiltersReset={handleFiltersReset}
      handleBulkObjUpdate={handleBulkObjUpdate}
      handleCheckAllOrderItems={handleCheckAllOrderItems}
      handleOrderCopy={handleOrderCopy}
      handleNumberChange={handleNumberChange}
      handleManufacturerUpdate={handleManufacturerUpdate}
      handleCategoryUpdate={handleCategoryUpdate}
      handleProjectUpdate={handleProjectUpdate}
      handleOrderItemChecked={handleOrderItemChecked}
      handleCountChange={handleCountChange}
      handleFileDownload={handleFileDownload}
      handleFileUpload={handleFileUpload}
      handleOrderDifferenceClick={handleOrderDifferenceClick}
      handleOrderItemSave={handleOrderItemSave}
      handleOrderItemDelete={handleOrderItemDelete}
      handleOrderItemMark={handleOrderItemMark}
      getEquipments={props.getEquipments}
      getOrderDifference={props.getOrderDifference}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    filters: state.order.itemsFilters,
    isLoading: state.order.isLoading,
    saveInProcess: state.order.saveInProcess,
    itemsIsLoading: state.order.itemsIsLoading,
    saveItemInProcess: state.order.saveItemInProcess,
    bulkActionInProcess: state.order.bulkActionInProcess,
    fileOperationInProcess: state.order.fileOperationInProcess,
    setAttrActionInProcess: state.order.setAttrActionInProcess,
    calculateDifferenceInProcess: state.order.calculateDifferenceInProcess,
  };
};
export default connect(mapStateToProps, {
  getOrder,
  getOrders,
  getOrderItems,
  getOrderFile,
  getManufacturers,
  getCategories,
  getBranches,
  getProjects,
  getOrdersDifferenceFile,
  getOrderDifference,
  getEquipments,
  setOrderItemManufacturer,
  setOrderItemCategory,
  setOrderItemProject,
  setOrderItemChecked,
  setOrderItemsFilters,
  setOrderFile,
  setNotification,
  bulkObjsUpdate,
  copyOrder,
  checkAllOrderItems,
  saveOrderItem,
  deleteOrderItem,
})(OrderDetailContainer);
