import React from "react";
import { connect } from "react-redux";
import {
  getKeywords,
  getManufacturers,
  saveObject,
  deleteObject,
  importMetaFile,
} from "redux/thunk/MetaThunk";
import KeywordList from "./KeywordList";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const KeywordListContainer = (props) => {
  const [keywords, setKeywords] = useState([]);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    page_number: 1,
    page_size: 20,
  });
  const {
    register: filtersRegister,
    handleSubmit: filtersApply,
    reset: filtersReset,
    control: filtersControl,
    watch: filtersWatch,
  } = useForm({
    defaultValues: {
      phrase: "",
      manufacturer: "",
    },
  });
  const [id, setId] = useState(null);
  const { register, handleSubmit, reset, control } = useForm({
    defaultValues: { phrase: "", manufacturer: "" },
  });
  const onSubmit = (data) => {
    console.log(data);
    props.saveObject(data, !id, "keywords").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowManageModal(false);
        props
          .getKeywords()
          .then((res) =>
            setKeywords(res && res.status === 200 ? res.data.keywords : [])
          );
      }
    });
  };
  const handleCountChange = (e) => {
    setFilters({ ...filters, page_size: Number(e.target.value) });
    props
      .getKeywords({ ...filters, page_size: Number(e.target.value) })
      .then((res) => {
        setKeywords(res && res.status === 200 ? res.data?.keywords : []);
        setCount(res && res.status === 200 ? res.data.count : 0);
      });
  };
  const handleNumberChange = (number) => {
    setFilters({ ...filters, page_number: number });
    props.getKeywords({ ...filters, page_number: number }).then((res) => {
      setKeywords(res && res.status === 200 ? res.data?.keywords : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFiltersApply = (data) => {
    setFilters({ ...filters, ...data });
    props.getKeywords({ ...filters, ...data }).then((res) => {
      setKeywords(res && res.status === 200 ? res.data?.keywords : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFiltersReset = () => {
    setFilters({ page_number: 1, page_size: 20 });
    filtersReset({
      phrase: "",
      manufacturer: "",
    });
    props.getKeywords({ page_number: 1, page_size: 20 }).then((res) => {
      setKeywords(res && res.status === 200 ? res.data?.keywords : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFileAttached = (e) => {
    props.importMetaFile(e.target.files[0], "keywords").then((res) => {
      props
        .getKeywords()
        .then((res) =>
          setKeywords(res && res.status === 200 ? res.data.keywords : [])
        );
    });
  };
  const handleObjectDelete = () => {
    props.deleteObject(id, "keywords").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowDeleteModal(false);
        props
          .getKeywords()
          .then((res) =>
            setKeywords(res && res.status === 200 ? res.data.keywords : [])
          );
      }
    });
  };
  useEffect(() => {
    props
      .getKeywords(filters)
      .then((res) =>
        setKeywords(res && res.status === 200 ? res.data.keywords : [])
      );
  }, []);
  useEffect(() => {
    reset(
      keywords.find((k) => k.id === id) || { phrase: "", manufacturer: "" }
    );
  }, [id, showManageModal]);
  return (
    <KeywordList
      reset={reset}
      handleSubmit={handleSubmit}
      register={register}
      onSubmit={onSubmit}
      setShowManageModal={setShowManageModal}
      setShowDeleteModal={setShowDeleteModal}
      setId={setId}
      showManageModal={showManageModal}
      showDeleteModal={showDeleteModal}
      handleObjectDelete={handleObjectDelete}
      getManufacturers={props.getManufacturers}
      keywords={keywords}
      control={control}
      handleCountChange={handleCountChange}
      handleNumberChange={handleNumberChange}
      handleFiltersApply={handleFiltersApply}
      handleFiltersReset={handleFiltersReset}
      handleFileAttached={handleFileAttached}
      filtersRegister={filtersRegister}
      filtersApply={filtersApply}
      filtersControl={filtersControl}
      filtersWatch={filtersWatch}
      count={filters.page_size}
      number={filters.page_number}
      totalCount={count}
      currId={id}
      fileOperationInProcess={props.fileOperationInProcess}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    fileOperationInProcess: state.meta.fileOperationInProcess,
  };
};
export default connect(mapStateToProps, {
  getKeywords,
  getManufacturers,
  saveObject,
  deleteObject,
  importMetaFile,
})(KeywordListContainer);
