import React from "react";
import { Card, Container, Row, Col, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import Paginator from "../../../components/Pagination/Paginator";
import Icon from "@mdi/react";
import { mdiPlusBoxOutline, mdiCheckAll, mdiTrashCan } from "@mdi/js";

const EquipmentTableRow = (props) => {
  return (
    <tr>
      <td><small>{props.number}</small></td>
      <td><small>{props.name}</small></td>
      <td><small>{props.sn}</small></td>
      <td><small>{props.directorate_repr ? props.directorate_repr: <b>Не указано</b>}</small></td>
      <td><small>{props.branch_repr ? props.branch_repr : <b>Не указано</b>}</small></td>
      <td>
        <small>{props.decommissioned ? "Не эксплуатируется" : "Эксплуатрируется"}</small>
      </td>
      <td>
        <small>{props.manufacturer_repr ? props.manufacturer_repr : <b>Не указано</b>}</small>
      </td>
      <td>
        <NavLink
          to={`/equipments/${props.id}/detail`}
          // to="/dashboard"
          className="btn-fill btn btn-info btn-sm"
        >
          Детально
        </NavLink>
      </td>
    </tr>
  );
};

const EquipmentList = (props) => {
  const equipments = props.equipments.map((e, index) => (
    <EquipmentTableRow key={e.id} number={index + 1} {...e} />
  ));
  const displayCountOptions = [
    { value: 5, label: "5 элементов" },
    { value: 10, label: "10 элементов" },
    { value: 15, label: "15 элементов" },
    { value: 20, label: "20 элементов" },
    { value: 25, label: "25 элементов" },
  ].map((e, index) => (
    <option value={e.value} key={index}>
      {e.label}
    </option>
  ));
  const decommissionedOptions = [
    { value: null, label: "" },
    { value: true, label: "Списана" },
    { value: false, label: "На балансе" },
  ].map((d, index) => (
    <option key={index} value={d.value}>
      {d.label}
    </option>
  ));

  return (
    <>
      <Container fluid>
        <Row>
          <Col className="sm-12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div>
                  <Card.Title as="h4">Оборудование</Card.Title>
                  <NavLink
                    to="/equipments/create"
                    className="btn-fill btn btn-sm btn-info mt-2"
                  >
                    Добавление оборудования
                    <Icon path={mdiPlusBoxOutline} size={1} className="ml-2" />
                  </NavLink>
                </div>

                <div>
                  <select
                    className="form-control form-control-sm"
                    name="pageSize"
                    value={props.count}
                    placeholder="Кол-во элементов"
                    onChange={props.handleCountChange}
                  >
                    {displayCountOptions}
                  </select>
                  <Paginator
                    className="mt-2 text-lg-right"
                    pageSize={props.count}
                    pageNumber={props.number}
                    totalCount={props.totalCount}
                    onClick={props.handleNumberChange}
                  />
                </div>
              </Card.Header>
              <Card.Body className="table-full-width table-responsive px-0">
                <form onSubmit={props.handleSubmit(props.onSubmit)}>
                  <Table className="table-hover table-sm">
                    <thead>
                      <tr>
                        <th>№</th>
                        <th>Модель</th>
                        <th>Сериный номер</th>
                        <th>Дирекция</th>
                        <th>РЦС</th>
                        <th>Эксплуатируется</th>
                        <th>Производитель</th>
                        <th></th>
                      </tr>
                      <tr>
                        <td colSpan={2}>
                          <input
                            type="text"
                            placeholder="Модель оборудования"
                            className="form-control form-control-sm"
                            {...props.register("name")}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="Серийный номер"
                            className="form-control form-control-sm"
                            {...props.register("sn")}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="Дирекция"
                            className="form-control form-control-sm"
                            {...props.register("directorate")}
                          />
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="РЦС"
                            className="form-control form-control-sm"
                            {...props.register("branch")}
                          />
                        </td>
                        <td>
                          <select className="form-control form-control-sm">{decommissionedOptions}</select>
                        </td>
                        <td>
                        <input
                            type="text"
                            placeholder="Производитель"
                            className="form-control form-control-sm"
                            {...props.register("manufacturer")}
                          />
                        </td>
                        <td>
                          <div className="d-flex justify-content-start">
                            <button className="btn-fill btn btn-info btn-sm">
                              <Icon path={mdiCheckAll} size={1} />
                            </button>
                            <button
                              className="btn-fill btn btn-danger btn-sm ml-2"
                              type="button"
                              onClick={props.reset}
                            >
                              <Icon path={mdiTrashCan} size={1} />
                            </button>
                          </div>
                        </td>
                      </tr>
                    </thead>
                    <tbody>{equipments}</tbody>
                  </Table>
                </form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EquipmentList;
