import React from "react";
import { NavLink } from "react-router-dom";
import { Row, Col, Container, Card, Modal } from "react-bootstrap";

const Admin = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Администрирование</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="d-flex justify-content-start">
                <Col md={12}>
                  <NavLink
                    to="/configure/manufacturers"
                    className="btn-fill btn btn-sm btn-info mr-2"
                  >
                    Производители
                  </NavLink>
                  <NavLink
                    to="/configure/directorates"
                    className="btn-fill btn btn-sm btn-info mr-2"
                  >
                    Дирекции
                  </NavLink>
                  <NavLink
                    to="/configure/branches"
                    className="btn-fill btn btn-sm btn-info mr-2"
                  >
                    Региональные центры связи
                  </NavLink>
                  <NavLink
                    to="/configure/categories"
                    className="btn-fill btn btn-sm btn-info mr-2"
                  >
                    Категории
                  </NavLink>
                  <NavLink
                    to="/configure/keywords"
                    className="btn-fill btn btn-sm btn-info mr-2"
                  >
                    Ключевые слова
                  </NavLink>
                  <NavLink
                    to="/configure/projects"
                    className="btn-fill btn btn-sm btn-info mr-2"
                  >
                    Проекты
                  </NavLink>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};
export default Admin;
