import api from "../../utils/api";
import { handleErrors } from "./HandleErrors";
import {
  setOrderItemsIsLoading,
  setOrders,
  setOrdersIsLoading,
  setOrderItemManufacturer as updateManufacturer,
  setBulkActionInProcess,
  setOrderFileOperationInProcess,
  setOrderItemAttrInProcess,
  setSaveOrderInProcess,
  setSaveOrderItemInProcess,
  setCalculateDifferenceInProcess,
} from "../actions/OrderActions";
import { setNotification } from "../actions/RootActions";
import qs from "qs";

export const getOrders = (filters = null) => {
  return async (dispatch, getState) => {
    const state = getState().order;
    try {
      const response = await api.get("v1/orders/list", {
        params: filters ? filters : state.filters,
      });
      dispatch(setOrdersIsLoading(false));
      return response;
    } catch (e) {
      dispatch(setOrdersIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getOrder = (id) => {
  return async (dispatch) => {
    dispatch(setOrdersIsLoading(true));
    try {
      const response = await api.get("v1/orders/detail", {
        params: { id: id },
      });
      dispatch(setOrdersIsLoading(false));
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getOrderItems = (id) => {
  return async (dispatch, getState) => {
    const state = getState().order;
    dispatch(setOrderItemsIsLoading(true));
    try {
      const response = await api.get("v1/orders/items/list", {
        params: { ...state.itemsFilters, id: id },
      });
      dispatch(setOrderItemsIsLoading(false));
      return response;
    } catch (e) {
      dispatch(setOrderItemsIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getOrderFile = (id) => {
  return async (dispatch) => {
    dispatch(setOrderFileOperationInProcess(true));
    try {
      const response = await api.get("v1/orders/export", {
        params: { id: id },
        responseType: "blob",
      });
      dispatch(setOrderFileOperationInProcess(false));
      return response;
    } catch (e) {
      dispatch(setOrderFileOperationInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getOrdersDifferenceFile = (sourceOrderId, targetOrderId) => {
  return async (dispatch) => {
    dispatch(setOrderFileOperationInProcess(true));
    try {
      const response = await api.get("v1/orders/compare-orders", {
        params: {
          source_order_id: sourceOrderId,
          target_order_id: targetOrderId,
        },
        responseType: "blob",
      });
      dispatch(setOrderFileOperationInProcess(false));
      return response;
    } catch (e) {
      dispatch(setOrderFileOperationInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getOrderDifference = (sourceOrderId, targetOrderId) => {
  return async (dispatch) => {
    dispatch(setCalculateDifferenceInProcess(true));
    try {
      const response = await api.get("v1/orders/compare-orders", {
        params: {
          source_order_id: sourceOrderId,
          target_order_id: targetOrderId,
        },
      });
      dispatch(setCalculateDifferenceInProcess(false));
      return response;
    } catch (e) {
      dispatch(setCalculateDifferenceInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setOrderFile = (
  id,
  file,
  hasManufacturers = false,
  hasProjects = false
) => {
  return async (dispatch) => {
    dispatch(setOrderFileOperationInProcess(true));
    try {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("file_has_manufacturers", hasManufacturers);
      formData.append("file_has_projects", hasProjects);
      const response = await api.put("v1/orders/set-file", formData, {
        params: { id: id },
      });
      dispatch(setOrderFileOperationInProcess(false));
      dispatch(
        setNotification(
          "Успешное выполнение",
          "Файл импортирован",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setOrderFileOperationInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setOrderItemCategory = (id, categoryNumber) => {
  return async (dispatch) => {
    dispatch(setOrderItemAttrInProcess(true));
    try {
      const response = await api.put(
        "v1/orders/items/set-category",
        {
          cat_number: categoryNumber,
        },
        {
          params: { id: id },
        }
      );
      dispatch(setOrderItemAttrInProcess(false));
      return response;
    } catch (e) {
      dispatch(setOrderItemAttrInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setOrderItemProject = (id, projectId) => {
  return async (dispatch) => {
    dispatch(setOrderItemAttrInProcess(true));
    try {
      const response = await api.put(
        "v1/orders/items/set-project",
        {
          project: projectId,
        },
        {
          params: { id: id },
        }
      );
      dispatch(setOrderItemAttrInProcess(false));
      dispatch(
        setNotification(
          "Успешное выполнение",
          "Изменения сохранены",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setOrderItemAttrInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setOrderItemChecked = (id) => {
  return async (dispatch) => {
    dispatch(setOrderItemAttrInProcess(true));
    try {
      const response = await api.put(
        "v1/equipments/set-checked",
        {
          manufacturer_needs_check: false,
        },
        {
          params: { id: id },
        }
      );
      dispatch(setOrderItemAttrInProcess(false));
      dispatch(
        setNotification(
          "Успешное выполнение",
          "Позиция отмечена как проверенная",
          "success",
          "",
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setOrderItemAttrInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const setOrderItemManufacturer = (id, manufacturerId) => {
  return async (dispatch) => {
    dispatch(setOrderItemAttrInProcess(true));
    try {
      const response = await api.put(
        "v1/equipments/set-manufacturer",
        {
          manufacturer: manufacturerId,
        },
        {
          params: {
            id: id,
          },
        }
      );
      dispatch(setOrderItemAttrInProcess(false));
      return response;
    } catch (e) {
      dispatch(setOrderItemAttrInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const bulkObjsUpdate = (data) => {
  return async (dispatch) => {
    dispatch(setBulkActionInProcess(true));
    const formData = new FormData();
    formData.append("type", data.type);
    formData.append("filter_field", data.filterField);
    formData.append("expr", data.expr);
    formData.append("filter_value", data.filterValue);
    formData.append("order_id", data.orderId);
    formData.append("id_to_update", data.id);
    try {
      const response = await api.put(
        "v1/bulk-actions/bulk-objs-update",
        formData
      );
      dispatch(
        setNotification(
          "Успешное выполнение",
          `Обновлено строк: ${response.data?.updated_objs?.length}`,
          "success",
          "",
          true
        )
      );
      dispatch(setBulkActionInProcess(false));
      return response;
    } catch (e) {
      dispatch(setBulkActionInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const copyOrder = (sourceId, targetId) => {
  return async (dispatch) => {
    dispatch(setBulkActionInProcess(true));
    try {
      const response = await api.post(
        "v1/bulk-actions/copy-order",
        {},
        {
          params: { source_order_id: sourceId, target_order_id: targetId },
        }
      );
      dispatch(setBulkActionInProcess(false));
      dispatch(
        setNotification(
          "Успешное выполнение",
          `Скопировано строк: ${response.data?.created}`,
          "success",
          "",
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setBulkActionInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const checkAllOrderItems = (orderId) => {
  return async (dispatch) => {
    dispatch(setBulkActionInProcess(true));
    try {
      const response = await api.post(
        "v1/bulk-actions/check-all-order-items",
        {},
        {
          params: { order_id: orderId },
        }
      );
      dispatch(setBulkActionInProcess(false));
      dispatch(
        setNotification(
          "Успешное выполнение",
          `Помечено позиций: ${response.data?.updated_objs}`,
          "success",
          "",
          true
        )
      );
      return response;
    } catch (e) {
      dispatch(setBulkActionInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveOrder = (data, create = true) => {
  return async (dispatch) => {
    let response;
    let alertText;
    dispatch(setSaveOrderInProcess(true));
    try {
      if (create) {
        response = await api.post("v1/orders/create", data);
        alertText = "Перечень создан";
      } else {
        response = await api.put("v1/orders/update", data, {
          params: { id: data.id },
        });
        alertText = "Изменения сохранены";
      }
      dispatch(setSaveOrderInProcess(false));
      dispatch(
        setNotification("Успешное выполнение", alertText, "success", null, true)
      );
      return response;
    } catch (e) {
      dispatch(setSaveOrderInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveOrderItem = (data, create = true) => {
  return async (dispatch) => {
    let response;
    let alertText;
    dispatch(setSaveOrderItemInProcess(true));
    try {
      if (create) {
        data.action_type = "added";
        response = await api.post("v1/orders/items/create", data);
        alertText = "Позиция добавлена в перечень";
      } else {
        response = await api.put("v1/orders/items/update", data, {
          params: { id: data.id },
        });
        alertText = "Изменения позиции сохранены";
      }
      dispatch(setSaveOrderItemInProcess(false));
      dispatch(
        setNotification("Успешное выполнение", alertText, "success", null, true)
      );
      return response;
    } catch (e) {
      dispatch(setSaveOrderItemInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const deleteOrderItem = (id) => {
  return async (dispatch) => {
    try {
      const response = await api.put(
        "v1/orders/items/delete",
        { action_type: "deleted" },
        {
          params: { id: id },
        }
      );
      dispatch(
        setNotification(
          "Успешное выполнение",
          "Позиция удалена",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
