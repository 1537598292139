export const actions = {
  SET_META_IS_LOADING: "SET_META_IS_LOADING",
  SET_META_FILE_OPERATION_IN_PROCESS: "SET_META_FILE_OPERATION_IN_PROCESS",
};
export const setMetaIsLoading = (isLoading) => {
  return {
    type: actions.SET_META_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setMetaFileOperationInProcess = (inProcess) => {
  return {
    type: actions.SET_META_FILE_OPERATION_IN_PROCESS,
    inProcess: inProcess,
  };
};
