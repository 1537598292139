import { actions } from "../actions/OrderActions";

const initialState = {
  orders: [],
  filters: {
    page_number: 1,
    page_size: 10,
  },
  itemsFilters: {
    page_number: 1,
    page_size: 100,
  },
  count: 0,
  isLoading: false,
  itemsIsLoading: false,
  saveInProcess: false,
  saveItemInProcess: false,
  setAttrActionInProcess: false,
  bulkActionInProcess: false,
  fileOperationInProcess: false,
  calculateDifferenceInProcess: false,
};
export const orderReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_ORDERS:
      return {
        ...state,
        orders: action.data,
        count: action.count,
      };
    case actions.SET_ORDERS_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_ORDER_ITEMS_IS_LOADING:
      return {
        ...state,
        itemsIsLoading: action.isLoading,
      };
    case actions.SET_ORDER_FILE_OPERATION_IN_PROCESS:
      return {
        ...state,
        fileOperationInProcess: action.inProcess,
      };
    case actions.SET_CALCULATE_DIFFERENCE_IN_PROCESS:
      return {
        ...state,
        calculateDifferenceInProcess: action.inProcess,
      };
    case actions.SET_ORDER_ITEM_ATTR_IN_PROCESS:
      return {
        ...state,
        setAttrActionInProcess: action.inProcess,
      };
    case actions.SET_SAVE_ORDER_IN_PROCESS:
      return {
        ...state,
        saveInProcess: action.inProcess,
      };
    case actions.SET_SAVE_ORDER_ITEM_IN_PROCESS:
      return {
        ...state,
        saveItemInProcess: action.inProcess,
      };
    case actions.SET_ORDER_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case actions.SET_ORDER_ITEMS_FILTERS:
      return {
        ...state,
        itemsFilters: action.data,
      };
    case actions.SET_BULK_ACTION_IN_PROCESS:
      return {
        ...state,
        bulkActionInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
