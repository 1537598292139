import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { formatDate, yesNo } from "../../../utils";

const EquipmentDetail = (props) => {
  const orderRows = props.equipment?.orders?.map((o, index) => (
    <tr key={o.id}>
      <td>{index + 1}</td>
      <td>{o.number}</td>
      <td>{o.directorate.name}</td>
      <td>{formatDate(o.starts_affect, "d.m.y")}</td>
      <td>
        <NavLink
          to={`/orders/${o.id}/detail`}
          className="btn-fill btn btn-sm btn-info"
        >
          Детально
        </NavLink>
      </td>
    </tr>
  ));
  return (
    <Container fluid>
      <Row>
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Оборудование</Card.Title>
            </Card.Header>
            <Card.Body>
              <Row className="mb-3">
                <Col md={6} className="d-flex justify-content-start">
                  <NavLink
                    to={`/equipments/${props.equipment.id}/update`}
                    className="btn-fill btn btn-info btn-sm mr-2"
                  >
                    Редактировать
                  </NavLink>
                  <NavLink
                    to={`/equipments/${props.equipment.id}/history`}
                    className="btn-fill btn btn-info btn-sm"
                  >
                    История изменений
                  </NavLink>
                </Col>
              </Row>
              <Row>
                <Col md={4}>Наименование:</Col>
                <Col md={8}>{props.equipment.name}</Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Серийный номер:</Col>
                <Col md={8}>{props.equipment.sn}</Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Инвентарный номер:</Col>
                <Col md={8}>{props.equipment.inv}</Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Сетевой номер:</Col>
                <Col md={8}>{props.equipment.nwk}</Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Производитель:</Col>
                <Col md={8}>
                  {yesNo(props.equipment.manufacturer)}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Базовый проект:</Col>
                <Col md={8}>
                  {yesNo(props.equipment.project)}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Дирекция:</Col>
                <Col md={8}>
                  {yesNo(props.equipment.directorate)}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>РЦС:</Col>
                <Col md={8}>
                  {yesNo(props.equipment.branch)}
                </Col>
              </Row>
              <hr />
              <Row>
                <Col md={4}>Расположение:</Col>
                <Col md={8}>
                  {props.equipment.location
                    ? props.equipment.location
                    : "Не указано"}
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
        <Col md={6}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Перечни ТП</Card.Title>
            </Card.Header>
            <Card.Body className="table-full-width table-responsive px-0 overflow-auto">
              <Table className="table-hover table-sm">
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Номер</th>
                    <th>Дирекция</th>
                    <th>Начало действия</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>{orderRows}</tbody>
              </Table>
            </Card.Body>
            <Card.Footer>
              <span className="text-muted">
                В таблице перечислены перечни, в которых встречается данная
                позиция
              </span>
            </Card.Footer>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default EquipmentDetail;
