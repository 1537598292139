import React, { useEffect, useState } from "react";
import EquipmentManage from "./EquipmentManage";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import {
  getEquipment,
  saveEquipment,
} from "../../../redux/thunk/EquipmentThunk";
import Spinner from "../../../components/Spinners/Spinner";
import {
  getBranches,
  getManufacturers,
  getProjects,
} from "../../../redux/thunk/MetaThunk";

const EquipmentManageContainer = (props) => {
  const params = useParams();
  const navigate = useNavigate();
  const [branches, setBranches] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const [projects, setProjects] = useState([]);
  const [equipment, setEquipment] = useState({});
  const {
    register,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    control,
  } = useForm({
    defaultValues: {},
  });
  useEffect(() => {
    if (params.id) {
      props.getEquipment(params.id).then((res) => {
        const data = { ...res.data };
        data.manufacturer = data.manufacturer.id;
        data.branch = data.branch.id;
        data.project = data.project.id
        setEquipment(data);
        reset(data);
      });
    }
    props
      .getBranches()
      .then((res) =>
        setBranches(res && res.status === 200 ? res.data.branches : [])
      );
    props
      .getManufacturers()
      .then((res) =>
        setManufacturers(
          res && res.status === 200 ? res.data.manufacturers : []
        )
      );
    props
      .getProjects()
      .then((res) =>
        res && res.status === 200 ? setProjects(res.data.projects) : []
      );
  }, [params.id]);
  const onSubmit = (data) => {
    const formData = { ...data };
    delete formData.directorate;
    props
      .saveEquipment(formData, !Object.keys(equipment).length > 0)
      .then((res) => navigate(`/equipments/${res.data.id}/detail`));
  };
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <EquipmentManage
      register={register}
      errors={errors}
      control={control}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      saveInProcess={props.saveInProcess}
      branches={branches}
      manufacturers={manufacturers}
      projects={projects}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    saveInProcess: state.equipment.saveInProcess,
    isLoading: state.equipment.isLoading,
  };
};
export default connect(mapStateToProps, {
  getEquipment,
  getBranches,
  getManufacturers,
  getProjects,
  saveEquipment,
})(EquipmentManageContainer);
