import api from "../../utils/api";

import { handleErrors } from "./HandleErrors";
import {
  setEquipment,
  setEquipmentIsLoading,
  setEquipments,
  setEquipmentSaveInProcess,
} from "../actions/EquipmentActions";
import { setNotification } from "../actions/RootActions";

export const getEquipments = (filters = null) => {
  return async (dispatch, getState) => {
    const state = getState().equipment;
    dispatch(setEquipmentIsLoading(true));
    try {
      const response = await api.get("v1/equipments/list", {
        params: filters ? filters : state.filters,
      });
      dispatch(setEquipments(response.data.equipments, response.data.count));
      dispatch(setEquipmentIsLoading(false));
      return response;
    } catch (e) {
      dispatch(setEquipmentIsLoading(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getEquipment = (id) => {
  return async (dispatch) => {
    dispatch(setEquipmentIsLoading(true));
    try {
      const response = await api.get("v1/equipments/detail", {
        params: { id: id },
      });
      dispatch(setEquipmentIsLoading(false));
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveEquipment = (data, create = true) => {
  return async (dispatch) => {
    let response = null;
    let resultText = "";
    try {
      dispatch(setEquipmentSaveInProcess(true));
      if (create) {
        response = await api.post("v1/equipments/create", data);
        resultText = "Оборудование создано";
      } else {
        response = await api.put("v1/equipments/update", data, {
          params: { id: data.id },
        });
        resultText = "Изменения сохранены";
      }
      dispatch(
        setNotification("Успешное выполнение", resultText, "success", "", true)
      );
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
