import api from "../../utils/api";
import {
  setAuthenticated,
  setAuthInProgress,
  setUserData,
  setUserDataIsLoading,
} from "../actions/RootActions";
import { handleErrors } from "./HandleErrors";

export const login = (data) => {
  return async (dispatch) => {
    dispatch(setAuthInProgress(true));
    try {
      const response = await api.post("v1/auth/login/", data);
      localStorage.setItem("token", response.data.access);
      dispatch(setUserData(response.data, true));
      dispatch(setAuthInProgress(false));
    } catch (e) {
      dispatch(setAuthInProgress(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const logout = () => {
  return async (dispatch) => {
    try {
      await api.post("v1/auth/logout/");
      localStorage.removeItem("token");
      dispatch(setUserData({}));
      dispatch(setAuthenticated(false));
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getUserData = () => {
  return async (dispatch) => {
    dispatch(setUserDataIsLoading(true));
    try {
      const response = await api.get("v1/users/self-data");
      dispatch(setUserData(response.data));
      dispatch(setUserDataIsLoading(false));
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
