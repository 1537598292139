import { actions } from "../actions/EquipmentActions";

const initialState = {
  equipments: [],
  equipment: {},
  filters: {
    page_size: 10,
    page_number: 1,
  },
  count: 0,
  isLoading: false,
  saveInProcess: false,
};
export const equipmentReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_EQUIPMENTS:
      return {
        ...state,
        equipments: action.data,
        count: action.count,
      };
    case actions.SET_EQUIPMENT:
      return {
        ...state,
        equipment: action.data,
      };
    case actions.SET_EQUIPMENT_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case actions.SET_EQUIPMENT_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_EQUIPMENT_SAVE_IN_PROCESS:
      return {
        ...state,
        saveInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
