import React from 'react';

const Spinner = () => {
    return (
        <div className="d-flex justify-content-center">
            <div className="text-center mt-5 mb-5">
                <div className="spinner-border text-info"/>
            </div>
        </div>
    )
}

export default Spinner;