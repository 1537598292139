import React from "react";
import { connect } from "react-redux";
import {
  getCategories,
  saveObject,
  deleteObject,
  importMetaFile,
} from "redux/thunk/MetaThunk";
import CategoryList from "./CategoryList";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const CategoryListContainer = (props) => {
  const [categories, setCategories] = useState([]);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    page_number: 1,
    page_size: 20,
  });
  const {
    register: filtersRegister,
    handleSubmit: filtersApply,
    reset: filtersReset,
  } = useForm({
    defaultValues: {
      name: "",
      number: "",
      price_without_tax: "",
      tax_value: "",
      price_with_tax: "",
    },
  });
  const [id, setId] = useState(null);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { name: "", number: "", price_without_tax: "" },
  });
  const onSubmit = (data) => {
    props.saveObject(data, !id, "categories").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowManageModal(false);
        props.getCategories().then((res) => {
          setCategories(res && res.status === 200 ? res.data.categories : []);
          setCount(res && res.status === 200 ? res.data.count : 0);
        });
      }
    });
  };
  const handleCountChange = (e) => {
    setFilters({ ...filters, page_size: Number(e.target.value) });
    props
      .getCategories({ ...filters, page_size: Number(e.target.value) })
      .then((res) => {
        setCategories(res && res.status === 200 ? res.data?.categories : []);
        setCount(res && res.status === 200 ? res.data.count : 0);
      });
  };
  const handleNumberChange = (number) => {
    setFilters({ ...filters, page_number: number });
    props.getCategories({ ...filters, page_number: number }).then((res) => {
      setCategories(res && res.status === 200 ? res.data?.categories : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFiltersApply = (data) => {
    setFilters({ ...filters, ...data });
    props.getCategories({ ...filters, ...data }).then((res) => {
      setCategories(res && res.status === 200 ? res.data?.categories : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFiltersReset = () => {
    setFilters({ page_number: 1, page_size: 20 });
    filtersReset({
      name: "",
      number: "",
      price_without_tax: "",
      tax_value: "",
      price_with_tax: "",
    });
    props.getCategories({ page_number: 1, page_size: 20 }).then((res) => {
      setCategories(res && res.status === 200 ? res.data?.categories : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFileAttached = (e) => {
    props.importMetaFile(e.target.files[0], "categories").then((res) => {
      props.getCategories(filters).then((res) => {
        setCategories(res && res.status === 200 ? res.data.categories : []);
        setCount(res && res.status === 200 ? res.data.count : 0);
      });
    });
  };
  const handleObjectDelete = () => {
    props.deleteObject(id, "categories").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowDeleteModal(false);
        props.getCategories().then((res) => {
          setCategories(res && res.status === 200 ? res.data.categories : []);
          setCount(res && res.status === 200 ? res.data.count : 0);
        });
      }
    });
  };
  useEffect(() => {
    props.getCategories().then((res) => {
      setCategories(res && res.status === 200 ? res.data.categories : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  }, []);
  useEffect(() => {
    reset(
      categories.find((d) => d.id === id) || {
        name: "",
        number: "",
        price_without_tax: "",
      }
    );
  }, [showManageModal]);
  return (
    <CategoryList
      reset={reset}
      handleSubmit={handleSubmit}
      register={register}
      onSubmit={onSubmit}
      setShowManageModal={setShowManageModal}
      setShowDeleteModal={setShowDeleteModal}
      setId={setId}
      showManageModal={showManageModal}
      showDeleteModal={showDeleteModal}
      handleObjectDelete={handleObjectDelete}
      categories={categories}
      handleCountChange={handleCountChange}
      handleNumberChange={handleNumberChange}
      handleFiltersApply={handleFiltersApply}
      handleFiltersReset={handleFiltersReset}
      handleFileAttached={handleFileAttached}
      filtersRegister={filtersRegister}
      filtersApply={filtersApply}
      count={filters.page_size}
      number={filters.page_number}
      totalCount={count}
      fileOperationInProcess={props.fileOperationInProcess}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    fileOperationInProcess: state.meta.fileOperationInProcess,
  };
};
export default connect(mapStateToProps, {
  getCategories,
  saveObject,
  deleteObject,
  importMetaFile,
})(CategoryListContainer);
