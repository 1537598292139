import thunkMiddleware from "redux-thunk";

import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";
import { rootReducer } from "./reducers/RootReducer";
import { equipmentReducer } from "./reducers/EquipmentReducer";
import { orderReducer } from "./reducers/OrderReducer";
import { statisticReducer } from "./reducers/StatisticReducer";
import { metaReducer } from "./reducers/MetaReducer";

const reducers = combineReducers({
  root: rootReducer,
  equipment: equipmentReducer,
  order: orderReducer,
  statistic: statisticReducer,
  meta: metaReducer
});
const store = createStore(reducers, applyMiddleware(thunkMiddleware));
export default store;
