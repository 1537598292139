import React from "react";
import { useLocation } from "react-router-dom";
import { Navbar, Container, Nav, Dropdown, Button } from "react-bootstrap";

import routes from "routes.js";
import Icon from "@mdi/react";
import { mdiMenu } from "@mdi/js";
import useScreenType from "react-screentype-hook";
import { useEffect } from "react";

const Header = (props) => {
  const location = useLocation();
  const screenType = useScreenType()
  const sidebarToggle = (e) => {
    e.preventDefault();
    const isToggled = document.documentElement.classList.toggle("nav-open");
    var node = document.createElement("div");
    node.id = "bodyClick";
    node.onclick = function () {
      this.parentElement.removeChild(this);
      document.documentElement.classList.toggle("nav-open");
      
    };
    document.body.appendChild(node);
  };
  useEffect(()=> {
    if (screenType.isMobile) {
      props.setShowSidebar(true)
    }
  }, [screenType.isMobile])
  
  const getBrandText = () => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.startsWith(routes[i].path)) {
        return routes[i].name;
      }
    }
    return "Страница не существует";
  };
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <button
          className="btn btn-sm ml-3"
          type="button"
          onClick={(e) => {
            if (screenType.isMobile){
              sidebarToggle(e)
            } else {
              props.setShowSidebar(!props.showSidebar)
            }
          }}
        >
          <Icon path={mdiMenu} size={1} />
        </button>
        <ul className="navbar-nav navbar-nav-right">
          <li className="nav-item  nav-profile border-0 pl-4">
            {getBrandText()}
          </li>
        </ul>
      </div>
      <div className="d-flex justify-content-end">
        <Dropdown>
          <Dropdown.Toggle as={Nav.Link} className="m-0 text-dark">
            <span className="no-icon text-dark">{props.fullName}</span>
          </Dropdown.Toggle>
          <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
            <Dropdown.Item onClick={() => props.logout()}>
              Выход из учетной записи
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </nav>
    // <Navbar bg="light" expand="lg">
    //   <Container fluid>
    //     <div className="d-flex justify-content-center align-items-center ml-2 ml-lg-0">
    //       <Button
    //         variant="dark"
    //         //className="d-lg-none btn-fill d-flex justify-content-center align-items-center-circle p-2"
    //         onClick={mobileSidebarToggle}
    //       >
    //         <Icon path={mdiMenu} size={1} className="mr-2" />
    //       </Button>
    //       <Navbar.Brand className="mr-2">{getBrandText()}</Navbar.Brand>
    //     </div>
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" className="mr-2">
    //       <span className="navbar-toggler-bar burger-lines"></span>
    //       <span className="navbar-toggler-bar burger-lines"></span>
    //       <span className="navbar-toggler-bar burger-lines"></span>
    //     </Navbar.Toggle>
    //     <Navbar.Collapse id="basic-navbar-nav">
    //       <Nav className="nav mr-auto" navbar></Nav>
    //       <Nav className="ml-auto" navbar>
    //         {props.userDataIsLoading ? (
    //           <div className="spinner-border spinner-border-sm text-info" />
    //         ) : (
    //           <Dropdown as={Nav.Item}>
    //             <Dropdown.Toggle
    //               aria-expanded={false}
    //               aria-haspopup={true}
    //               as={Nav.Link}
    //               data-toggle="dropdown"
    //               id="navbarDropdownMenuLink"
    //               variant="default"
    //               className="m-0"
    //             >
    //               <span className="no-icon">{props.fullName}</span>
    //             </Dropdown.Toggle>
    //             <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
    //               <Dropdown.Item onClick={() => props.logout()}>
    //                 Выход из учетной записи
    //               </Dropdown.Item>
    //             </Dropdown.Menu>
    //           </Dropdown>
    //         )}
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>
  );
};

export default Header;
