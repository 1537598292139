import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import Select from "react-select";

const OrderManage = (props) => {
  const directoratesOptions = props.directorates.map((d) => ({
    value: d.id,
    label: d.name,
  }));
  return (
    <Container fluid>
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Перечень ТП</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="mb-2 mt-2">
                  <Col md={3}>Номер</Col>
                  <Col md={9}>
                    <input
                      type="text"
                      className={`form-control form-control-sm ${
                        props.errors.number ? "is-invalid" : ""
                      }`}
                      placeholder="Номер"
                      {...props.register("number", { required: true })}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>Дата начала действия</Col>
                  <Col md={9}>
                    <input
                      type="date"
                      className={`form-control form-control-sm ${
                        props.errors.starts_affect ? "is-invalid" : ""
                      }`}
                      placeholder="Дата начала действия"
                      {...props.register("starts_affect", {
                        required: true,
                        valueAsDate: true,
                      })}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>РЦС</Col>
                  <Col md={9}>
                    <Controller
                      name="directorate"
                      control={props.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            options={directoratesOptions}
                            isClearable={true}
                            defaultValue={null}
                            placeholder="Дирекция"
                            value={
                              directoratesOptions.find(
                                (p) => p.value === value
                              ) || null
                            }
                            onChange={(e) => onChange(e ? e.value : null)}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <button className="btn-fill btn-sm btn btn-info">
                  {props.saveInProcess ? (
                    <div className="spinner-border spinner-border-sm text-white" />
                  ) : (
                    "Сохранить изменения"
                  )}
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default OrderManage;
