import { actions } from "../actions/StatisticActions";

const initialState = {
  filters: {
    start: new Date().getFullYear(),
    end: new Date().getFullYear() + 1,
    type: 'per_year'
  },
  isLoading: false,
  fileCreateInProcess: false,
};

export const statisticReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_STATISTIC_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_STATISTIC_FILTERS:
      return {
        ...state,
        filters: action.data,
      };
    case actions.SET_STATISTIC_FILE_CREATE_IN_PROCESS:
      return {
        ...state,
        fileCreateInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
