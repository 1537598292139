import React from "react";
import { connect } from "react-redux";
import { getProjects, saveObject, deleteObject } from "redux/thunk/MetaThunk";
import ProjectList from "./ProjectList";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";

const ProjectListContainer = (props) => {
  const [projects, setProjects] = useState([]);
  const [showManageModal, setShowManageModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [count, setCount] = useState(0);
  const [filters, setFilters] = useState({
    page_number: 1,
    page_size: 20,
  });
  const {
    register: filtersRegister,
    handleSubmit: filtersApply,
    reset: filtersReset,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });
  const [id, setId] = useState(null);
  const { register, handleSubmit, reset } = useForm({
    defaultValues: { name: "" },
  });
  const onSubmit = (data) => {
    props.saveObject(data, !id, "projects").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowManageModal(false);
        props.getProjects().then((res) => {
          setProjects(res && res.status === 200 ? res.data.projects : []);
          setCount(res && res.status === 200 ? res.data.count : []);
        });
      }
    });
  };
  const handleCountChange = (e) => {
    setFilters({ ...filters, page_size: Number(e.target.value) });
    props
      .getProjects({ ...filters, page_size: Number(e.target.value) })
      .then((res) => {
        setProjects(res && res.status === 200 ? res.data?.projects : []);
        setCount(res && res.status === 200 ? res.data.count : 0);
      });
  };
  const handleNumberChange = (number) => {
    setFilters({ ...filters, page_number: number });
    props.getProjects({ ...filters, page_number: number }).then((res) => {
      setProjects(res && res.status === 200 ? res.data?.projects : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFiltersApply = (data) => {
    setFilters({ ...filters, ...data });
    props.getProjects({ ...filters, ...data }).then((res) => {
      setProjects(res && res.status === 200 ? res.data?.projects : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleFiltersReset = () => {
    setFilters({ page_number: 1, page_size: 20 });
    filtersReset({
      name: "",
    });
    props.getProjects({ page_number: 1, page_size: 20 }).then((res) => {
      setProjects(res && res.status === 200 ? res.data?.projects : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  };
  const handleObjectDelete = () => {
    props.deleteObject(id, "projects").then((res) => {
      if (res && res.status === 200) {
        setId(null);
        setShowDeleteModal(false);
        props.projects(filters).then((res) => {
          setProjects(res && res.status === 200 ? res.data.projects : []);
          setCount(res && res.status === 200 ? res.data.count : 0);
        });
      }
    });
  };
  useEffect(() => {
    props.getProjects(filters).then((res) => {
      setProjects(res && res.status === 200 ? res.data.projects : []);
      setCount(res && res.status === 200 ? res.data.count : 0);
    });
  }, []);
  useEffect(() => {
    reset(projects.find((p) => p.id === id) || { name: "" });
  }, [showManageModal]);
  return (
    <ProjectList
      reset={reset}
      handleSubmit={handleSubmit}
      register={register}
      onSubmit={onSubmit}
      setShowManageModal={setShowManageModal}
      setShowDeleteModal={setShowDeleteModal}
      setId={setId}
      showManageModal={showManageModal}
      showDeleteModal={showDeleteModal}
      handleObjectDelete={handleObjectDelete}
      projects={projects}
      handleCountChange={handleCountChange}
      handleNumberChange={handleNumberChange}
      handleFiltersApply={handleFiltersApply}
      handleFiltersReset={handleFiltersReset}
      filtersRegister={filtersRegister}
      filtersApply={filtersApply}
      count={filters.page_size}
      number={filters.page_number}
      totalCount={count}
    />
  );
};
const mapStateToProps = (state) => {
  return {};
};
export default connect(mapStateToProps, {
  getProjects,
  saveObject,
  deleteObject,
})(ProjectListContainer);
