export const actions = {
  SET_ORDERS: "SET_ORDERS",
  SET_ORDER_FILTERS: "SET_ORDER_FILTERS",
  SET_ORDER_ITEMS_FILTERS: "SET_ORDER_ITEMS_FILTERS",
  SET_ORDERS_IS_LOADING: "SET_ORDERS_IS_LOADING",
  SET_ORDER_ITEMS_IS_LOADING: "SET_ORDER_ITEMS_IS_LOADING",
  SET_ORDER_ITEM_MANUFACTURER: "SET_ORDER_ITEM_MANUFACTURER",
  SET_BULK_ACTION_IN_PROCESS: "SET_BULK_ACTION_IN_PROCESS",
  SET_ORDER_FILE_OPERATION_IN_PROCESS: "SET_ORDER_FILE_OPERATION_IN_PROCESS",
  SET_CALCULATE_DIFFERENCE_IN_PROCESS: "SET_CALCULATE_DIFFERENCE_IN_PROCESS",
  SET_ORDER_ITEM_ATTR_IN_PROCESS: "SET_ORDER_ITEM_ATTR_IN_PROCESS",
  SET_SAVE_ORDER_IN_PROCESS: "SET_SAVE_ORDER_IN_PROCESS",
  SET_SAVE_ORDER_ITEM_IN_PROCESS: "SET_SAVE_ORDER_ITEM_IN_PROCESS",
};
export const setOrders = (data, count) => {
  return {
    type: actions.SET_ORDERS,
    data: data,
    count: count,
  };
};
export const setOrderFilters = (data) => {
  return {
    type: actions.SET_ORDER_FILTERS,
    data: data,
  };
};
export const setOrderItemsIsLoading = (isLoading) => {
  return {
    type: actions.SET_ORDER_ITEMS_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setOrderItemsFilters = (data) => {
  return {
    type: actions.SET_ORDER_ITEMS_FILTERS,
    data: data,
  };
};
export const setOrdersIsLoading = (isLoading) => {
  return {
    type: actions.SET_ORDERS_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setOrderFileOperationInProcess = (inProcess) => {
  return {
    type: actions.SET_ORDER_FILE_OPERATION_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setCalculateDifferenceInProcess = (inProcess) => {
  return {
    type: actions.SET_CALCULATE_DIFFERENCE_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setOrderItemAttrInProcess = (inProcess) => {
  return {
    type: actions.SET_ORDER_ITEM_ATTR_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setOrderItemManufacturer = (
  id,
  manufacturerId,
  manufacturerName
) => {
  return {
    type: actions.SET_ORDER_ITEM_MANUFACTURER,
    id: id,
    manufacturerId: manufacturerId,
    manufacturerName: manufacturerName,
  };
};
export const setBulkActionInProcess = (inProcess) => {
  return {
    type: actions.SET_BULK_ACTION_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setSaveOrderInProcess = (inProcess) => {
  return {
    type: actions.SET_SAVE_ORDER_IN_PROCESS,
    inProcess: inProcess,
  };
};
export const setSaveOrderItemInProcess = (inProcess) => {
  return {
    type: actions.SET_SAVE_ORDER_ITEM_IN_PROCESS,
    inProcess: inProcess,
  };
};
