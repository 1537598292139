import React, { useEffect } from "react";
import App from "./App";
import { connect } from "react-redux";
import LoginContainer from "../views/login/LoginContainer";
import { setNotification } from "../redux/actions/RootActions";
import { getUserData, logout } from "../redux/thunk/RootThunk";
import Spinner from "components/Spinners/Spinner";

const AppContainer = (props) => {
  useEffect(() => {
    if (localStorage.getItem("token")) {
      props.getUserData();
    }
  }, [props.isAuthenticated]);

  if (Object.keys(props.userData).length === 0 && props.isAuthenticated) {
    return <Spinner />;
  }
  return localStorage.getItem("token") ? (
    <App
      userData={props.userData}
      notifications={props.notifications}
      setNotification={props.setNotification}
      userDataIsLoading={props.userDataIsLoading}
      logout={props.logout}
    />
  ) : (
    <LoginContainer />
  );
};
const mapStateToProps = (state) => {
  return {
    userData: state.root.userData,
    isAuthenticated: state.root.isAuthenticated,
    authInProcess: state.root.authInProcess,
    notifications: state.root.notifications,
    userDataIsLoading: state.root.userDataIsLoading,
  };
};
export default connect(mapStateToProps, {
  setNotification,
  getUserData,
  logout,
})(AppContainer);
