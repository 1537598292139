import React, { useEffect, useState } from "react";
import OrderList from "./OrderList";
import Spinner from "../../../components/Spinners/Spinner";
import { connect } from "react-redux";
import { getOrders } from "../../../redux/thunk/OrderThunk";
import { useForm } from "react-hook-form";
import { getDirectorates } from "../../../redux/thunk/MetaThunk";
import { setOrderFilters } from "../../../redux/actions/OrderActions";

const OrderListContainer = (props) => {
  const [orders, setOrders] = useState([]);
  const [directorates, setDirectorates] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const filters = { ...props.filters };
  const { register, handleSubmit, reset, control } = useForm({
    defaultValue: {},
  });
  const onSubmit = (data) => {
    props.setOrderFilters({
      ...filters,
      ...data,
      page_number: 1,
      page_size: 10,
    });
    props.getOrders().then((res) => {
      if (res && res.status === 200) {
        setOrders(res.data.orders);
        setTotalCount(res.data.count);
      }
    });
  };
  const handleCountChange = (e) => {
    props.setOrderFilters({
      ...filters,
      page_size: Number(e.target.value),
    });
    props.getOrders().then((res) => {
      if (res && res.status === 200) {
        setOrders(res.data.orders);
        setTotalCount(res.data.count);
      }
    });
  };
  const handleNumberChange = (number) => {
    props.setOrderFilters({
      ...filters,
      page_number: number,
    });
    props.getOrders().then((res) => {
      if (res && res.status === 200) {
        setOrders(res.data.orders);
        setTotalCount(res.data.count);
      }
    });
  };
  const handleFiltersReset = () => {
    props.setOrderFilters({
      page_number: 1,
      page_size: 10,
    });
    reset({});
    props.getOrders().then((res) => {
      if (res && res.status === 200) {
        setOrders(res.data.orders);
        setTotalCount(res.data.count);
      }
    });
  };
  useEffect(() => {
    props
      .getDirectorates()
      .then((res) =>
        setDirectorates(res && res.status === 200 ? res.data.directorates : [])
      );
    props.getOrders().then((res) => {
      if (res && res.status === 200) {
        setOrders(res.data.orders);
        setTotalCount(res.data.count);
      }
    });
  }, []);
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <OrderList
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      register={register}
      control={control}
      handleCountChange={handleCountChange}
      handleNumberChange={handleNumberChange}
      reset={handleFiltersReset}
      orders={orders}
      totalCount={totalCount}
      count={filters.page_size}
      number={filters.page_number}
      directorates={directorates}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    orders: state.order.orders,
    filters: state.order.filters,
    isLoading: state.order.isLoading,
  };
};
export default connect(mapStateToProps, {
  setOrderFilters,
  getOrders,
  getDirectorates,
})(OrderListContainer);
