import React, { useEffect, useState } from "react";
import EquipmentList from "./EquipmentList";
import { connect } from "react-redux";
import { getEquipments } from "../../../redux/thunk/EquipmentThunk";
import Spinner from "../../../components/Spinners/Spinner";
import { useForm } from "react-hook-form";
import {
  getBranches,
  getDirectorates,
  getManufacturers,
} from "../../../redux/thunk/MetaThunk";
import { setEquipmentFilters } from "../../../redux/actions/EquipmentActions";

const EquipmentListContainer = (props) => {
  const filters = { ...props.filters };
  const [directorates, setDirectorates] = useState([]);
  const [branches, setBranches] = useState([]);
  const [manufacturers, setManufacturers] = useState([]);
  const { register, handleSubmit, reset, control, watch } = useForm({
    defaultValues: filters,
  });

  const onSubmit = (data) => {
    props.setEquipmentFilters({
      ...filters,
      ...data,
      page_number: 1,
      page_size: 10,
    });
    props.getEquipments();
  };
  const handleCountChange = (e) => {
    props.setEquipmentFilters({
      ...filters,
      page_size: Number(e.target.value),
    });
    props.getEquipments();
  };
  const handleNumberChange = (number) => {
    props.setEquipmentFilters({
      ...filters,
      page_number: number,
    });
    props.getEquipments();
  };
  const handleFiltersReset = () => {
    props.setEquipmentFilters({
      page_number: 1,
      page_size: 10,
    });
    reset({});
    props.getEquipments();
  };
  useEffect(() => {
    props
      .getBranches({ directorate: watch("directorate") })
      .then((res) =>
        setBranches(res && res.status === 200 ? res.data.branches : [])
      );
  }, [watch("directorate")]);
  useEffect(() => {
    props
      .getDirectorates()
      .then((res) =>
        setDirectorates(res && res.status === 200 ? res.data.directorates : [])
      );
    props
      .getBranches()
      .then((res) =>
        setBranches(res && res.status === 200 ? res.data.branches : [])
      );
    props
      .getManufacturers()
      .then((res) =>
        setManufacturers(
          res && res.status === 200 ? res.data.manufacturers : []
        )
      );
    props.getEquipments();
  }, []);
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <EquipmentList
      handleCountChange={handleCountChange}
      handleNumberChange={handleNumberChange}
      register={register}
      handleSubmit={handleSubmit}
      control={control}
      reset={handleFiltersReset}
      onSubmit={onSubmit}
      count={filters.page_size}
      number={filters.page_number}
      manufacturers={manufacturers}
      branches={branches}
      directorates={directorates}
      {...props}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    equipments: state.equipment.equipments,
    filters: state.equipment.filters,
    totalCount: state.equipment.count,
    isLoading: state.equipment.isLoading,
  };
};
export default connect(mapStateToProps, {
  setEquipmentFilters,
  getEquipments,
  getDirectorates,
  getBranches,
  getManufacturers,
})(EquipmentListContainer);
