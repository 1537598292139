export const actions = {
  SET_USER_DATA: "SET_USER_DATA",
  SET_IS_AUTHENTICATED: "SET_IS_AUTHENTICATED",
  SET_NOTIFICATION: "SET_NOTIFICATION",
  SET_AUTH_IN_PROGRESS: "SET_AUTH_IN_PROGRESS",
  SET_USER_DATA_IS_LOADING: "SET_USER_DATA_IS_LOADING",
};
export const setUserData = (data, isAuthenticated) => {
  return {
    type: actions.SET_USER_DATA,
    data: data,
    isAuthenticated: isAuthenticated,
  };
};
export const setAuthenticated = (isAuthenticated) => {
  return {
    type: actions.SET_IS_AUTHENTICATED,
    isAuthenticated: isAuthenticated,
  };
};
export const setAuthInProgress = (inProgress) => {
  return {
    type: actions.SET_AUTH_IN_PROGRESS,
    inProgress: inProgress,
  };
};
export const setNotification = (title, body, level, icon, add, id) => {
  return {
    type: actions.SET_NOTIFICATION,
    id: id,
    add: add,
    title: title,
    body: body,
    level: level,
    icon: icon,
  };
};
export const setUserDataIsLoading = (isLoading) => {
  return {
    type: actions.SET_USER_DATA_IS_LOADING,
    isLoading: isLoading,
  };
};
