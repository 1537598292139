import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import Select from "react-select";
import { Controller } from "react-hook-form";

const EquipmentManage = (props) => {
  const manufacturersOptions = props.manufacturers.map((m) => ({
    value: m.id,
    label: m.name,
  }));
  const branchesOptions = props.branches.map((b) => ({
    value: b.id,
    label: b.name,
  }));
  const projectsOptions = props.projects.map((p)=>({
    value: p.id,
    label: p.name
  }))
  return (
    <Container fluid>
      <form onSubmit={props.handleSubmit(props.onSubmit)}>
        <Row>
          <Col md={6}>
            <Card>
              <Card.Header>
                <Card.Title as="h4">Оборудование</Card.Title>
              </Card.Header>
              <Card.Body>
                <Row className="mb-2 mt-2">
                  <Col md={3}>Модель оборудования:</Col>
                  <Col md={9}>
                    <input
                      type="text"
                      className={`form-control form-control-sm ${
                        props.errors.name ? "is-invalid" : ""
                      }`}
                      placeholder="Модель оборудования"
                      {...props.register("name", { required: true })}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>Серийный номер</Col>
                  <Col md={9}>
                    <input
                      type="text"
                      className={`form-control form-control-sm ${
                        props.errors.sn ? "is-invalid" : ""
                      }`}
                      placeholder="Серийный номер"
                      {...props.register("sn", { required: true })}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>Инвентарный номер</Col>
                  <Col md={9}>
                    <input
                      type="text"
                      className={`form-control form-control-sm ${
                        props.errors.inv ? "is-invalid" : ""
                      }`}
                      placeholder="Инвентарный номер"
                      {...props.register("inv", { required: false })}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>Сетевой номер</Col>
                  <Col md={9}>
                    <input
                      type="text"
                      className={`form-control form-control-sm ${
                        props.errors.nwk ? "is-invalid" : ""
                      }`}
                      placeholder="Сетевой номер"
                      {...props.register("nwk", { required: false })}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>РЦС</Col>
                  <Col md={9}>
                    <Controller
                      name="branch"
                      control={props.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            options={branchesOptions}
                            isClearable={true}
                            defaultValue={null}
                            placeholder="Региональный центр связи"
                            value={
                              branchesOptions.find((p) => p.value === value) ||
                              null
                            }
                            onChange={(e) => onChange(e ? e.value : null)}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>Производитель</Col>
                  <Col md={9}>
                    <Controller
                      name="manufacturer"
                      control={props.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            options={manufacturersOptions}
                            isClearable={true}
                            defaultValue={null}
                            placeholder="Производитель"
                            value={
                              manufacturersOptions.find(
                                (p) => p.value === value
                              ) || null
                            }
                            onChange={(e) => onChange(e ? e.value : null)}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <Row className="mb-2">
                  <Col md={3}>Проект</Col>
                  <Col md={9}>
                    <Controller
                      name="project"
                      control={props.control}
                      render={({ field: { onChange, value } }) => {
                        return (
                          <Select
                            options={projectsOptions}
                            isClearable={true}
                            defaultValue={null}
                            placeholder="Проект"
                            value={
                              projectsOptions.find(
                                (p) => p.value === value
                              ) || null
                            }
                            onChange={(e) => onChange(e ? e.value : null)}
                          />
                        );
                      }}
                    />
                  </Col>
                </Row>
                <button className="btn-fill btn-sm btn btn-info">
                  Сохранить изменения
                </button>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </form>
    </Container>
  );
};

export default EquipmentManage;
