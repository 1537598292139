import React from "react";
import logo from "../../assets/img/logo-black.png";
import { Card, Container, Row, Col } from "react-bootstrap";
import NotificationToast from "components/Toasts/NotificationToast";

const Login = (props) => {
  const notifications = props.notifications
  ? props.notifications.map((t, index) => (
      <NotificationToast key={index} onClose={props.setNotification} {...t} />
    ))
  : [];
  debugger
  return (
    <Container fluid className="mt-5">
      <div className="d-flex justify-content-end align-items-center">
        <div
          style={{
            position: "absolute",
            top: 0,
            right: 15,
            zIndex: 9999,
          }}
        >
          {notifications.length > 0 ? notifications : null}
        </div>
      </div>
      <Row className="d-flex justify-content-center">
        <Col md="5">
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                <div className="d-flex justify-content-center">
                  <div className="brand-logo">
                    <img src={logo} alt="logo" width="250" height="60" />
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <h5>Для доступа введите учетные данные</h5>
                </div>
              </Card.Title>
            </Card.Header>
            <Card.Body>
              <form onSubmit={props.handleSubmit(props.onSubmit)}>
                <input
                  type="text"
                  className={`form-control form-control-sm mb-2 ${
                    props.errors.username ? "is-invalid" : ""
                  }`}
                  placeholder="Имя пользователя"
                  {...props.register("username", { required: true })}
                />
                <input
                  type="password"
                  className={`form-control form-control-sm mb-2 ${
                    props.errors.password ? "is-invalid" : ""
                  }`}
                  placeholder="Пароль"
                  {...props.register("password", { required: true })}
                />
                <button className="btn-fill btn btn-info btn-sm">
                  {props.authInProcess ? (
                    <div className="spinner-border spinner-border-sm text-white" />
                  ) : (
                    "Вход"
                  )}
                </button>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Login;
