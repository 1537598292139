import { actions } from "redux/actions/MetaActions";

const initialState = {
  isLoading: false,
  fileOperationInProcess: false,
};
export const metaReducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_META_IS_LOADING:
      return {
        ...state,
        isLoading: action.isLoading,
      };
    case actions.SET_META_FILE_OPERATION_IN_PROCESS:
      return {
        ...state,
        fileOperationInProcess: action.inProcess,
      };
    default:
      return state;
  }
};
