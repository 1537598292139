export const actions = {
  SET_EQUIPMENTS: "SET_EQUIPMENTS",
  SET_EQUIPMENT: "SET_EQUIPMENT",
  SET_EQUIPMENT_FILTERS: "SET_EQUIPMENT_FILTERS",
  SET_EQUIPMENT_IS_LOADING: "SET_EQUIPMENT_IS_LOADING",
  SET_EQUIPMENT_SAVE_IN_PROCESS: "SET_EQUIPMENT_SAVE_IN_PROCESS",
};
export const setEquipments = (data, count) => {
  return {
    type: actions.SET_EQUIPMENTS,
    data: data,
    count: count,
  };
};
export const setEquipment = (data) => {
  return {
    type: actions.SET_EQUIPMENT,
    data: data,
  };
};
export const setEquipmentFilters = (data) => {
  return {
    type: actions.SET_EQUIPMENT_FILTERS,
    data: data,
  };
};
export const setEquipmentIsLoading = (isLoading) => {
  return {
    type: actions.SET_EQUIPMENT_IS_LOADING,
    isLoading: isLoading,
  };
};
export const setEquipmentSaveInProcess = (inProcess) => {
  return {
    type: actions.SET_EQUIPMENT_SAVE_IN_PROCESS,
    inProcess: inProcess,
  };
};
