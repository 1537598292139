export const months = {
  1: "Январь",
  2: "Февраль",
  3: "Март",
  4: "Апрель",
  5: "Май",
  6: "Июнь",
  7: "Июль",
  8: "Август",
  9: "Сентябрь",
  10: "Октябрь",
  11: "Ноябрь",
  12: "Декабрь",
};
export const getMonthRepr = (number) => {
  if (number && number > 0) {
    return months[number];
  }
  return "Не указано";
};
export const yesNo = (obj, property = "name") => {
  if (!obj || Object.keys(obj).length === 0 || !obj[property]) {
    return "Не указано";
  }
   
  return obj[property];
};
export const formatDate = (text, format = "d.m.y H:M") => {
  const d = new Date(text);
  const f = [...format];
  const dateObj = {
    d: d.getDate().toString().length < 2 ? `0${d.getDate()}` : d.getDate(),
    m:
      (d.getMonth() + 1).toString().length < 2
        ? `0${d.getMonth() + 1}`
        : d.getMonth() + 1,
    y: d.getFullYear(),
    H: d.getHours().toString().length < 2 ? `0${d.getHours()}` : d.getHours(),
    M:
      d.getMinutes().toString().length < 2
        ? `0${d.getMinutes()}`
        : d.getMinutes(),
    S:
      d.getSeconds().toString().length < 2
        ? `0${d.getSeconds()}`
        : d.getSeconds(),
  };
  let resultDate = "";
  f.forEach((el) => {
    dateObj.hasOwnProperty(el)
      ? (resultDate += dateObj[el])
      : (resultDate += el);
  });
  return resultDate;
};
