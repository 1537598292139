import { setNotification } from "redux/actions/RootActions";
import api from "../../utils/api";
import { handleErrors } from "./HandleErrors";
import { setMetaFileOperationInProcess } from "redux/actions/MetaActions";

export const getDirectorates = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/directorates/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getCategories = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/categories/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getBranches = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/branches/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getManufacturers = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/manufacturers/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getProjects = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/projects/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getKeywords = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/keywords/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getEventLogs = (filters = null) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/eventlogs/list", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const getEventLogDetail = (filters) => {
  return async (dispatch) => {
    try {
      return await api.get("v1/meta/eventlogs/detail", {
        params: filters && filters,
      });
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const saveObject = (data, create = true, target = "directorates") => {
  return async (dispatch) => {
    try {
      let response;
      let alertText;
      if (create) {
        response = await api.post(`v1/meta/${target}/create`, data);
        alertText = "Объект создан";
      } else {
        response = await api.put(`v1/meta/${target}/update`, data, {
          params: { id: data.id },
        });
        alertText = "Изменения сохранены";
      }
      dispatch(
        setNotification("Успешное выполнение", alertText, "success", null, true)
      );
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const deleteObject = (id, target = "directorates") => {
  return async (dispatch) => {
    try {
      const response = await api.delete(`v1/meta/${target}/delete`, {
        params: { id: id },
      });
      dispatch(
        setNotification(
          "Успешное выполнение",
          "Объект удален",
          "success",
          null,
          true
        )
      );
      return response;
    } catch (e) {
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
export const importMetaFile = (file, target = "keywords") => {
  return async (dispatch) => {
    dispatch(setMetaFileOperationInProcess(true));
    try {
      const formData = new FormData();
      formData.append("file", file);
      const response = await api.post(
        `v1/meta/${target}/import-file`,
        formData
      );
      dispatch(
        setNotification(
          "Успешное выполнение",
          "Файл импортирован",
          "success",
          null,
          true
        )
      );
      dispatch(setMetaFileOperationInProcess(false));
      return response;
    } catch (e) {
      dispatch(setMetaFileOperationInProcess(false));
      if (e.response) {
        dispatch(handleErrors(e));
      }
    }
  };
};
