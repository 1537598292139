import React from "react";
import { Toast } from "react-bootstrap";
import { mdiCloseThick } from "@mdi/js";
import Icon from "@mdi/react";

const NotificationToast = (props) => {
  const getMessageText = (text) => {
    if (props.body[0] instanceof Object) {
      if (props.body[0].hasOwnProperty("message")) {
        return props.body["0"].message;
      }
      return "Неизвестная ошибка";
    }
    return text;
  };
  const levels = {
    success: "#87CB16",
    warning: "FF9500",
    danger: "#FF4A55",
  };
  return (
    <Toast style={{ zIndex: -1 }} className="text-white">
      <Toast.Header
        closeButton={false}
        className="d-flex justify-content-between p-1 text-white"
        style={{ background: levels[props.level] }}
      >
        <div>{props.title}</div>
        <div
          className="p-0 text-black "
          onClick={() => props.onClose("", "", "", "", false, props.id)}
        >
          <Icon path={mdiCloseThick} size={1} />
        </div>
      </Toast.Header>
      <Toast.Body
        className="px-5 py-2 d-flex justify-content-start"
        style={{ background: levels[props.level] }}
      >
        <strong className="mt-1">{getMessageText(props.body)}</strong>
      </Toast.Body>
    </Toast>
  );
};

export default NotificationToast;
