import React from "react";
import { Container, Row, Col } from "react-bootstrap";

const NotFoundPage = (props) => {
  return (
    <Container fluid>
      <Row>
        <Col className="sm-12">
            Запрашиваемая страница не существует или была перемещена
        </Col>
      </Row>
    </Container>
  );
};

export default NotFoundPage;
