import React, { useEffect, useState } from "react";
import {
  Card,
  Col,
  Container,
  Dropdown,
  Modal,
  Nav,
  Row,
  Table,
} from "react-bootstrap";
import { NavLink, useNavigate } from "react-router-dom";
import Paginator from "../../../components/Pagination/Paginator";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import AsyncSelect from "react-select/async";
import Icon from "@mdi/react";
import {
  mdiAccountHardHatOutline,
  mdiBriefcaseCheckOutline,
  mdiCashMultiple,
  mdiCheck,
  mdiCheckAll,
  mdiDotsVertical,
  mdiDownload,
  mdiEarth,
  mdiFileEditOutline,
  mdiFileMultipleOutline,
  mdiFileReplaceOutline,
  mdiFileUploadOutline,
  mdiInformationOutline,
  mdiPlusBoxOutline,
  mdiTrashCan,
  mdiHistory,
  mdiFactory,
  mdiClose,
} from "@mdi/js";
import Spinner from "../../../components/Spinners/Spinner";
import { yesNo, getMonthRepr, months } from "../../../utils";
import { Collapse } from "react-bootstrap";
import Form from "react-bootstrap/Form";

const OrderItemManageModal = (props) => {
  const categoryOptions = props.categories.map((c) => ({
    value: c.number,
    label: c.name,
  }));
  const { register, control, reset, handleSubmit } = useForm({
    defaultValues: {
      id: null,
      equipment: null,
      cat_number: null,
      starts_affect: 1,
      ends_affect: 1,
    },
  });
  useEffect(() => {
    if (!props.create) {
      reset({
        id: props.currObj.id,
        equipment: props.currObj.equipmentId,
        cat_number: props.currObj.cat_number,
        project: props.currObj.project,
        starts_affect: props.currObj.starts_affect,
        ends_affect: props.currObj.ends_affect,
      });
    } else {
      reset({
        id: null,
        equipment: null,
        cat_number: null,
        starts_affect: 1,
        ends_affect: 1,
      });
    }
  }, [props.currObj]);
  const getData = (inputValue, callback) => {
    props
      .getEquipments({
        page_size: 50,
        page_number: 1,
        name: inputValue,
      })
      .then((res) => {
        if (res && res.status === 200) {
          callback(() => {
            return res.data.equipments.map((e) => ({
              value: e.id,
              label: `${e.name} S/N:${e.sn} ${e.branch_repr}`,
            }));
          });
        }
      });
  };
  const monthOptions = Object.keys(months).map((k, index) => (
    <option key={k} value={k}>
      {months[k]}
    </option>
  ));

  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Добавление оборудования в перечень</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form
          onSubmit={handleSubmit((data) => props.onSave(data, props.create))}
        >
          <Row>
            <Col md={3}>Оборудование</Col>
            <Col md={9}>
              {props.create ? (
                <Controller
                  name="equipment"
                  control={control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <AsyncSelect
                        placeholder="Оборудование"
                        noOptionsMessage={() => "Результаты отсутствуют"}
                        loadingMessage={() => "Загрузка..."}
                        cacheOptions
                        isClearable
                        loadOptions={getData}
                        onChange={(e) => onChange(e ? e.value : null)}
                      />
                    );
                  }}
                />
              ) : (
                <span>
                  {props.currObj.name} {props.currObj.sn}
                </span>
              )}
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>Категория</Col>
            <Col md={9}>
              <Controller
                name="cat_number"
                control={control}
                render={({ field: { onChange, value } }) => {
                  console.log(value);
                  return (
                    <Select
                      options={categoryOptions}
                      isClearable={true}
                      defaultValue={null}
                      placeholder="Категория"
                      value={
                        categoryOptions.find((p) => p.value === value) || null
                      }
                      onChange={(e) => onChange(e ? e.value : null)}
                    />
                  );
                }}
              />
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>Начало действия</Col>
            <Col md={9}>
              <select
                className="form-control form-control-sm"
                {...register("starts_affect")}
              >
                {monthOptions}
              </select>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>Завершение действия</Col>
            <Col md={9}>
              <select
                className="form-control form-control-sm"
                {...register("ends_affect")}
              >
                {monthOptions}
              </select>
            </Col>
          </Row>
          <Row className="mt-2">
            <Col md={3}>
              <button
                className="btn-fill btn btn-sm btn-info mt-3"
                disabled={props.saveItemInProcess}
              >
                {props.saveItemInProcess ? (
                  <div className="spinner-border spinner-border-sm text-white" />
                ) : (
                  "Сохранить изменения"
                )}
              </button>
            </Col>
          </Row>
        </form>
      </Modal.Body>
    </Modal>
  );
};
const DownloadOrderFileModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Сохранение файла перечня ТП</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-4">
          <div className="spinner-border text-info" />
        </div>
      </Modal.Body>
    </Modal>
  );
};
const UploadFileOrderModal = (props) => {
  const [file, setFile] = useState(null);
  const [fileHasManufacturers, setFileHasManufacturers] = useState(false);
  const [fileHasProjects, setFileHasProjects] = useState(false);
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Загрузка перечня ТП</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={4}>Файл перечня ТП:</Col>
          <Col md={8}>
            <input
              type="file"
              className="form-control form-control-sm"
              onChange={(e) => setFile(e.target.files[0])}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>Загрузить производителей из файла</Col>
          <Col md={8}>
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => setFileHasManufacturers(e.target.checked)}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={4}>Загрузить проекты из файла</Col>
          <Col md={8}>
            <input
              type="checkbox"
              className="form-check-input"
              onChange={(e) => setFileHasProjects(e.target.checked)}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          disabled={props.fileOperationInProcess}
          onClick={() =>
            props.onSave(file, fileHasManufacturers, fileHasProjects)
          }
        >
          {props.fileOperationInProcess ? (
            <div className="spinner-border spinner-border-sm text-white" />
          ) : (
            "Сохранить"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const ApproveCheckAllModal = (props) => {
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Проверка позиций перечня технической поддержки</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Все позиции перечня будут помечены как проверенные пользователем <br />
        <b>Продолжить?</b>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          disabled={props.bulkActionInProcess}
          onClick={() => props.onSave()}
        >
          {props.bulkActionInProcess ? (
            <div className="spinner-border spinner-border-sm text-white" />
          ) : (
            "Отметить все позиции"
          )}
        </button>
        <button
          className="btn-fill btn btn-sm btn-danger"
          disabled={props.bulkActionInProcess}
          onClick={props.handleClose}
        >
          Отмена
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const OrderCopyModal = (props) => {
  const ordersOptions = props.orders.map((o) => ({
    value: o.id,
    label: `${o.number}, ${o.directorate.name}`,
  }));
  const [orderId, setOrderId] = useState(null);
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Копирование перечня технической поддержки</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <span className="mt-2">Перечень ТП:</span>
          </Col>
          <Col md={9}>
            <Select
              options={ordersOptions}
              isClearable
              onChange={(e) => {
                setOrderId(e ? e.value : null);
              }}
            />
          </Col>
        </Row>
        <hr />
        <small>
          <span className="text-muted">
            Выберите перечень технической поддержки, который требуется
            скопировать в текущий открытый перечень.
            <br />
            Обратите внимание, что в списке перечислены перечни, относящиеся к
            той же дирекции, что и текущий открытый перечень.
            <br />
          </span>
        </small>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          disabled={props.bulkActionInProcess}
          onClick={() => props.onSave(orderId)}
        >
          {props.bulkActionInProcess ? (
            <div className="spinner-border spinner-border-sm text-white" />
          ) : (
            "Сохранить изменения"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const OrderDifferenceModal = (props) => {
  const ordersOptions = props.orders.map((o) => ({
    value: o.id,
    label: `${o.number}, ${o.directorate.name}`,
  }));
  const [targetOrderId, setTargetOrderId] = useState(null);
  const [statistic, setStatistic] = useState({
    source_order_number: "",
    target_order_number: "",
    source_order_total_amount: 0,
    target_order_total_amount: 0,
    manufacturers: [],
    projects: [],
  });
  const manufacturerOptions = statistic.manufacturers.map((r, index) => (
    <tr key={index}>
      <td>
        <small>{r.name}</small>
      </td>
      <td>
        <small>
          {Number(r.data.source.price_without_tax).toLocaleString()}
        </small>
      </td>
      <td>
        <small>{Number(r.data.source.tax_value).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.source.price_with_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.source.count).toLocaleString()}</small>
      </td>
      <td>
        <small>
          {Number(r.data.target.price_without_tax).toLocaleString()}
        </small>
      </td>
      <td>
        <small>{Number(r.data.target.tax_value).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.target.price_with_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.target.count).toLocaleString()}</small>
      </td>
    </tr>
  ));
  const projectOptions = statistic.projects.map((r, index) => (
    <tr key={index}>
      <td>
        <small>{r.name}</small>
      </td>
      <td>
        <small>
          {Number(r.data.source.price_without_tax).toLocaleString()}
        </small>
      </td>
      <td>
        <small>{Number(r.data.source.tax_value).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.source.price_with_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.source.count).toLocaleString()}</small>
      </td>
      <td>
        <small>
          {Number(r.data.target.price_without_tax).toLocaleString()}
        </small>
      </td>
      <td>
        <small>{Number(r.data.target.tax_value).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.target.price_with_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(r.data.target.count).toLocaleString()}</small>
      </td>
    </tr>
  ));
  useEffect(() => {
    if (targetOrderId) {
      props
        .getOrderDifference(props.sourceOrderId, targetOrderId)
        .then((res) => {
          if (res && res.status === 200) {
            debugger;
            setStatistic(res.data);
          }
        });
    }
  }, [targetOrderId]);
  return (
    <Modal
      show={props.show}
      onHide={props.handleClose}
      size="lg"
      fullscreen={true}
    >
      <Modal.Header>
        <Modal.Title>
          <h5>Сравнение перечней</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {props.calculateDifferenceInProcess ? (
          <div className="spinner-border spinner-border-sm text-info" />
        ) : (
          <>
            <Row>
              <Col md={3}>
                <span className="mt-2">Перечень ТП:</span>
              </Col>
              <Col md={9}>
                <Select
                  options={ordersOptions}
                  isClearable
                  value={ordersOptions.find((o) => o.value === targetOrderId)}
                  onChange={(e) => {
                    setTargetOrderId(e ? e.value : null);
                  }}
                />
              </Col>
            </Row>
            {statistic.manufacturers.length > 0 ? (
              <div
                className="overflow-auto mt-3"
                style={{ maxHeight: "600px" }}
              >
                <Row className="mt-3">
                  <Col md={12}>
                    <div>
                      <b>Сравнение по производителям</b>
                    </div>
                    <hr />
                    <Table className="table-hover table-sm table-bordered">
                      <thead>
                        <tr>
                          <td rowSpan={2}>
                            <small>
                              <b>Наименование</b>
                            </small>
                          </td>
                          <td colSpan={4} className="text-center">
                            {statistic.source_order_number}
                            <br />
                            <small>
                              Итоговая сумма с НДС:{" "}
                              {Number(
                                statistic.source_order_total_amount
                              ).toLocaleString()}
                            </small>
                          </td>
                          <td colSpan={4} className="text-center">
                            {statistic.target_order_number}
                            <br />
                            <small>
                              Итоговая сумма с НДС:{" "}
                              {Number(
                                statistic.target_order_total_amount
                              ).toLocaleString()}
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>
                              <b>Стоимость без НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Сумма НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Стоимость с НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Количество</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Стоимость без НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Сумма НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Стоимость с НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Количество</b>
                            </small>
                          </td>
                        </tr>
                      </thead>
                      <tbody>{manufacturerOptions}</tbody>
                    </Table>
                  </Col>
                  <Col md={12}>
                    <div>
                      <b>Сравнение по проектам</b>
                    </div>
                    <hr />
                    <Table className="table-hover table-sm table-bordered">
                      <thead>
                        <tr>
                          <td rowSpan={2}>
                            <small>
                              <b>Наименование</b>
                            </small>
                          </td>
                          <td colSpan={4} className="text-center">
                            {statistic.source_order_number}
                            <br />
                            <small>
                              Итоговая сумма с НДС:{" "}
                              {Number(
                                statistic.source_order_total_amount
                              ).toLocaleString()}
                            </small>
                          </td>
                          <td colSpan={4} className="text-center">
                            {statistic.target_order_number}
                            <br />
                            <small>
                              Итоговая сумма с НДС:{" "}
                              {Number(
                                statistic.target_order_total_amount
                              ).toLocaleString()}
                            </small>
                          </td>
                        </tr>
                        <tr>
                          <td>
                            <small>
                              <b>Стоимость без НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Сумма НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Стоимость с НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Количество</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Стоимость без НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Сумма НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Стоимость с НДС</b>
                            </small>
                          </td>
                          <td>
                            <small>
                              <b>Количество</b>
                            </small>
                          </td>
                        </tr>
                      </thead>
                      <tbody>{projectOptions}</tbody>
                    </Table>
                  </Col>
                </Row>
              </div>
            ) : null}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <small>
          <span className="text-muted">
            Выберите перечень технической поддержки, с которым требуется
            сравнить текущий перечень ТП
            <br />
            Обратите внимание, что в списке перечислены перечни, относящиеся к
            той же дирекции, что и текущий открытый перечень.
            <br />
          </span>
        </small>
      </Modal.Footer>
    </Modal>
  );
};
const BulkActionModal = (props) => {
  const [filterField, setFilterField] = useState("name");
  const [expr, setExpr] = useState("icontains");
  const [filterValue, setFilterValue] = useState("");
  const [id, setId] = useState(null);
  const filterFieldsOptions = [
    { value: "name", label: "Модель" },
    { value: "sn", label: "Серийный номер" },
    { value: "inv", label: "Инвентарный номер" },
    { value: "nwk", label: "Сетевой номер" },
    { value: "location", label: "Расположение" },
    { value: "id", label: "Выделенные строки" },
  ];
  const exprOptions = [
    { value: "icontains", label: "Содержит" },
    { value: "exact", label: "Совпадает" },
    { value: "startswith", label: "Начинается" },
    { value: "endswith", label: "Заканчивается" },
    { value: "in", label: "Попадает в список" },
  ].map((f, index) => (
    <option key={index} value={f.value}>
      {f.label}
    </option>
  ));
  const options = props.objs.map((m) => ({ value: m.id, label: m.name }));
  const typesOptions = [
    { value: "manufacturer", label: "производителя" },
    { value: "category", label: "категории" },
    { value: "branch", label: "РЦС" },
    { value: "project", label: "проекта" },
  ];
  const getTypeRepr = () => {
    if (!props.type) {
      return "<CHECK ACTION TYPE>";
    }
    return typesOptions.find((e) => e.value === props.type).label;
  };
  const handleSave = () => {
    props.onSave({
      type: props.type,
      filterField: filterField,
      expr: expr,
      filterValue: filterValue,
      id: id,
    });
  };
  useEffect(() => {
    if (props.show) {
      setFilterField("name");
      setExpr("icontains");
      setFilterValue("");
      setId(null);
    }
  }, [props.show]);
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>Массовая установка {getTypeRepr()}</h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <span className="mt-2">Поиск по полю:</span>
          </Col>
          <Col md={9}>
            <select
              className="form-control form-control-sm"
              onChange={(e) => {
                if (e.target.value === "id") {
                  setExpr("in");
                  setFilterValue(props.checkedItems);
                } else {
                  setExpr("icontains");
                  setFilterValue("");
                }
                setFilterField(e.target.value);
              }}
            >
              {filterFieldsOptions.map((f, index) => (
                <option key={index} value={f.value}>
                  {f.label}
                </option>
              ))}
            </select>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <span className="mt-2">Условие поиска:</span>
          </Col>
          <Col md={9}>
            <select
              className="form-control form-control-sm"
              disabled={filterField === "id"}
              value={expr}
              onChange={(e) => setExpr(e.target.value)}
            >
              {exprOptions}
            </select>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <span className="mt-2">Искомое значение:</span>
          </Col>
          <Col md={9}>
            <input
              type="text"
              disabled={filterField === "id"}
              className="form-control form-control-sm"
              value={filterValue}
              onChange={(e) => setFilterValue(e.target.value)}
            />
          </Col>
        </Row>
        <hr />
        <Row>
          <Col md={3}>
            <span className="mt-2">Установить значение:</span>
          </Col>
          <Col md={9}>
            <Select
              options={options}
              isClearable
              onChange={(e) => {
                setId(e ? e.value : null);
              }}
            />
          </Col>
        </Row>
        <div className="text-muted mt-3">
          Для осуществления массового действия задайте параметры выборки, где
          <br />
          <b>Поиск по полю</b> - поле, по которому будет осуществлена выборка
          <br />
          <b>Условие поиска</b> - условие, используемое для выборки.
          <br />
          <b>Искомое значение</b> - значение, по которому будет отфильтрована
          выборка, например, СМК-30 MUX
        </div>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          disabled={props.bulkActionInProcess}
          onClick={() => {
            handleSave();
          }}
        >
          {props.bulkActionInProcess ? (
            <div className="spinner-border spinner-border-sm text-white" />
          ) : (
            "Сохранить изменения"
          )}
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const SetManufacturerModal = (props) => {
  const [value, setValue] = useState(null);
  const options = props.manufacturers.map((o) => ({
    value: o.id,
    label: o.name,
  }));
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>
            {props.currObj.name} {props.currObj.sn}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <span className="mt-2">Атрибут:</span>
          </Col>
          <Col md={9}>
            <Select
              options={options}
              defaultValue={options.find(
                (m) => m.value === props.currObj.selectedValue
              )}
              isClearable
              onChange={(e) => {
                setValue(e ? e.value : null);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          onClick={() => {
            props.onSave(props.currObj.id, value);
            props.handleClose();
          }}
        >
          Сохранить
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const SetCategoryModal = (props) => {
  const [value, setValue] = useState(null);
  const options = props.categories.map((o) => ({
    value: o.number,
    label: o.name,
  }));
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>
            {props.currObj.name} {props.currObj.sn}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <span className="mt-2">Категория:</span>
          </Col>
          <Col md={9}>
            <Select
              options={options}
              defaultValue={options.find(
                (o) => o.value === props.currObj.selectedValue
              )}
              isClearable
              onChange={(e) => {
                setValue(e ? e.value : null);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          onClick={() => {
            props.onSave(props.currObj.id, value);
            props.handleClose();
          }}
        >
          Сохранить
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const SetProjectModal = (props) => {
  const [value, setValue] = useState(null);
  const options = props.projects.map((o) => ({ value: o.id, label: o.name }));
  return (
    <Modal show={props.show} onHide={props.handleClose} size="lg">
      <Modal.Header>
        <Modal.Title>
          <h5>
            {props.currObj.name} {props.currObj.sn}
          </h5>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col md={3}>
            <span className="mt-2">Проект:</span>
          </Col>
          <Col md={9}>
            <Select
              options={options}
              defaultValue={options.find(
                (o) => o.value === props.currObj.selectedValue
              )}
              isClearable
              onChange={(e) => {
                setValue(e ? e.value : null);
              }}
            />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <button
          className="btn-fill btn btn-sm btn-info"
          onClick={() => {
            props.onSave(props.currObj.id, value);
            props.handleClose();
          }}
        >
          Сохранить
        </button>
      </Modal.Footer>
    </Modal>
  );
};
const OrderItemTableRow = (props) => {
  const navigate = useNavigate();
  const getClassName = () => {
    if (props.condition) {
      return props.equipment.manufacturer_needs_check ? "table-danger" : "";
    } else {
      return props.action_type === "added"
        ? "table-success"
        : props.action_type === "deleted"
        ? "table-warning"
        : "";
    }
  };
  return (
    <tr className={getClassName()}>
      <td>
        <small>{props.number}</small>
      </td>
      <td>
        <small>{props.equipment.name}</small>
      </td>
      <td>
        <small>{props.equipment.sn}</small>
      </td>
      <td>
        <small>{props.cat_number}</small>
      </td>
      <td>
        <small>{props.price_with_tax}</small>
      </td>
      <td>
        <small>{getMonthRepr(props.starts_affect)}</small>
      </td>
      <td>
        <small>{getMonthRepr(props.ends_affect)}</small>
      </td>
      <td>
        <small>{yesNo(props.manufacturer)}</small>
      </td>
      <td className="p-0">
        <small>
          Проект перечня: <b>{yesNo(props.project)}</b>
          <br />
          Корневой проект: <b>{yesNo(props.root_project)}</b>
        </small>
      </td>
      <td>
        <small>{yesNo(props.branch)}</small>
      </td>
      <td>
        <button
          className={`p-0 btn btn-sm btn-fill btn-info bg-white text-dark`}
          onClick={(e) => {
            e.preventDefault();
            props.handleOrderItemMark(
              props.equipment.id,
              !props.checkedItems.some((id) => id === props.equipment.id)
            );
          }}
        >
          <small>
            <Icon
              path={
                props.checkedItems.some((id) => id === props.equipment.id) &&
                mdiCheck
              }
              size={0.7}
            />
          </small>
        </button>
      </td>
      <td>
        {props.action_type !== "deleted" && (
          <Dropdown as={Nav.Item}>
            <Dropdown.Toggle as={Nav.Link} className="m-0">
              <span className="no-icon">
                <Icon path={mdiDotsVertical} size={1} />
              </span>
            </Dropdown.Toggle>
            <Dropdown.Menu aria-labelledby="navbarDropdownMenuLink">
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  navigate(`/equipments/${props.equipment.id}/detail`);
                }}
              >
                <Icon
                  path={mdiInformationOutline}
                  size={1}
                  className="ml-0 mr-2"
                />
                Карточка оборудования
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  props.setCurrObj({
                    id: props.id,
                    name: props.equipment.name,
                    sn: props.equipment.sn,
                    equipmentId: props.equipment.id,
                    cat_number: props.cat_number,
                    project: props.project.id,
                    starts_affect: props.starts_affect,
                    ends_affect: props.ends_affect,
                  });
                  props.setCreate(false);
                  props.setShowOrderItemManageModal(true);
                }}
              >
                <Icon
                  path={mdiFileEditOutline}
                  size={1}
                  className="ml-0 mr-2"
                />
                Редактировать
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  props.setCurrObj({
                    id: props.equipment.id,
                    name: props.equipment.name,
                    sn: props.equipment.sn,
                    selectedValue: props.manufacturer.id,
                  });
                  props.setShowManufacturerModal(true);
                }}
              >
                <Icon path={mdiFactory} size={1} className="ml-0 mr-2" />
                Установить производителя
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  props.setCurrObj({
                    id: props.id,
                    name: props.equipment.name,
                    sn: props.equipment.sn,
                    selectedValue: props.cat_number,
                  });
                  props.setShowCategoryModal(true);
                }}
              >
                <Icon path={mdiCashMultiple} size={1} className="ml-0 mr-2" />
                Установить категорию
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) => {
                  e.preventDefault();
                  props.setCurrObj({
                    id: props.id,
                    name: props.equipment.name,
                    sn: props.equipment.sn,
                    selectedValue: props.project.id,
                  });
                  props.setShowProjectModal(true);
                }}
              >
                <Icon
                  path={mdiBriefcaseCheckOutline}
                  size={1}
                  className="ml-0 mr-2"
                />
                Установить проект
              </Dropdown.Item>
              <Dropdown.Item
                onClick={(e) =>
                  props.handleOrderItemChecked(props.equipment.id)
                }
              >
                <Icon path={mdiCheck} size={1} className="ml-0 mr-2" />
                Отметить как проверенную
              </Dropdown.Item>
              <Dropdown.Item
                href="#pablo"
                onClick={() => props.handleOrderItemDelete(props.id)}
              >
                <Icon
                  path={mdiTrashCan}
                  color="red"
                  size={1}
                  className="ml-0 mr-2"
                />
                Удалить позицию
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        )}
      </td>
    </tr>
  );
};
const StatisticTableRow = (props) => {
  return (
    <tr>
      <td>
        <small>{props.name}</small>
      </td>
      <td>
        <small>{Number(props.price_without_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(props.tax_value).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(props.price_with_tax).toLocaleString()}</small>
      </td>
    </tr>
  );
};

const OrderDetail = (props) => {
  const [currObj, setCurrObj] = useState({});
  const [showOrderInfo, setShowOrderInfo] = useState(false);
  const [objs, setObjs] = useState([]);
  const [type, setType] = useState(null);
  const [create, setCreate] = useState(false);
  const [showHistory, setShowHistory] = useState(false);
  let rowCounter = 0;

  const displayCountOptions = [
    { value: 5, label: "5 элементов" },
    { value: 50, label: "50 элементов" },
    { value: 100, label: "100 элементов" },
    { value: 200, label: "200 элементов" },
    { value: 300, label: "300 элементов" },
  ].map((e, index) => (
    <option value={e.value} key={index}>
      {e.label}
    </option>
  ));
  const statisticManufacturerOptions =
    props.order?.statistic?.manufacturers.map((m) => (
      <StatisticTableRow key={m.id} {...m} />
    ));
  const statisticProjectOptions = props.order?.statistic?.projects.map((p) => (
    <StatisticTableRow key={p.id} {...p} />
  ));

  const orderItems = props.orderItems.map((i) => {
    let condition = "";
    if (!showHistory) {
      condition = "deleted";
    }

    if (i.action_type !== condition) {
      rowCounter += 1;
      return (
        <OrderItemTableRow
          key={i.id}
          number={rowCounter}
          condition={condition}
          setCurrObj={setCurrObj}
          checkedItems={props.checkedItems}
          setShowManufacturerModal={props.setShowManufacturerModal}
          setShowCategoryModal={props.setShowCategoryModal}
          setShowProjectModal={props.setShowProjectModal}
          setShowManageModal={props.setShowManageModal}
          setShowOrderItemManageModal={props.setShowOrderItemManageModal}
          setCreate={setCreate}
          handleOrderItemChecked={props.handleOrderItemChecked}
          handleOrderItemDelete={props.handleOrderItemDelete}
          handleOrderItemMark={props.handleOrderItemMark}
          {...i}
        />
      );
    }
  });
  return (
    <>
      <OrderItemManageModal
        show={props.showOrderItemManageModal}
        currObj={currObj}
        create={create}
        categories={props.categories}
        projects={props.projects}
        handleClose={() => {
          setCurrObj({});
          props.setShowOrderItemManageModal(false);
        }}
        onSave={props.handleOrderItemSave}
        setCurrObj={setCurrObj}
        getEquipments={props.getEquipments}
        saveItemInProcess={props.saveItemInProcess}
      />
      {/*Модальное окно одничной установки производителя позиции*/}
      <SetManufacturerModal
        show={props.showManufacturerModal}
        currObj={currObj}
        manufacturers={props.manufacturers}
        handleClose={() => {
          props.setShowManufacturerModal(false);
        }}
        onSave={props.handleManufacturerUpdate}
      />

      {/*Модальное окно установки категории позиции*/}
      <SetCategoryModal
        show={props.showCategoryModal}
        currObj={currObj}
        categories={props.categories}
        handleClose={() => {
          props.setShowCategoryModal(false);
        }}
        onSave={props.handleCategoryUpdate}
      />

      {/*Модальное окно установки проекта позиции*/}
      <SetProjectModal
        show={props.showProjectModal}
        currObj={currObj}
        projects={props.projects}
        handleClose={() => {
          props.setShowProjectModal(false);
        }}
        onSave={props.handleProjectUpdate}
      />

      {/*Модальное окно массовой установки атрибута позиций*/}
      <BulkActionModal
        show={props.showBulkActionModal}
        objs={objs}
        type={type}
        bulkActionInProcess={props.bulkActionInProcess}
        checkedItems={props.checkedItems}
        handleClose={() => {
          if (!props.bulkActionInProcess) {
            props.setShowBulkActionModal(false);
          }
        }}
        onSave={props.handleBulkObjUpdate}
      />

      {/*Модальное окно копирования перечня*/}
      <OrderCopyModal
        show={props.showOrderCopyModal}
        orders={props.orders}
        bulkActionInProcess={props.bulkActionInProcess}
        handleClose={() => {
          props.setShowOrderCopyModal(false);
        }}
        onSave={props.handleOrderCopy}
      />

      <OrderDifferenceModal
        show={props.showOrderDifferenceModal}
        orders={props.orders}
        fileOperationInProcess={props.fileOperationInProcess}
        calculateDifferenceInProcess={props.calculateDifferenceInProcess}
        handleClose={() => {
          props.setShowOrderDifferenceModal(false);
        }}
        getOrderDifference={props.getOrderDifference}
        sourceOrderId={props.order.id}
        onSave={props.handleOrderDifferenceClick}
      />

      {/*Модальное окно подтверждения проверки всех позиций перечня*/}
      <ApproveCheckAllModal
        show={props.showAlertModal}
        bulkActionInPropcess={props.bulkActionInProcess}
        handleClose={() => {
          props.setShowAlertModal(false);
        }}
        onSave={props.handleCheckAllOrderItems}
      />

      {/*Модальное сохранения файла перечня*/}
      <DownloadOrderFileModal
        show={props.showFileDownloadModal}
        handleClose={() => {
          if (!props.fileOperationInProcess) {
            props.setShowFileDownloadModal(false);
          }
        }}
      />

      {/*Модальное загрузки и обработки файла перечня*/}
      <UploadFileOrderModal
        show={props.showFileUploadModal}
        fileOperationInProcess={props.fileOperationInProcess}
        handleClose={() => {
          props.setShowFileUploadModal(false);
        }}
        onSave={props.handleFileUpload}
      />

      {/*Контейнер информации о перечне*/}
      <Container fluid>
        <button
          className="btn-fill btn btn-sm btn-info mb-3"
          onClick={() => setShowOrderInfo(!showOrderInfo)}
          aria-controls="order-info-collapse"
        >
          {showOrderInfo ? "Скрыть информацию" : "Отобразить информацию"}
        </button>
        <Collapse in={showOrderInfo}>
          <div id="order-info-collapse">
            <Row>
              <Col md={6}>
                <Card>
                  <Card.Header>
                    <Card.Title as="h4">
                      Перечень технической поддержки {props.order.number}
                    </Card.Title>
                  </Card.Header>
                  <Card.Body>
                    {props.isLoading ? (
                      <Spinner />
                    ) : (
                      <>
                        <Row className="mb-3">
                          <Col md={2}>
                            <NavLink
                              to={`/orders/${props.order.id}/update`}
                              className="btn-fill btn btn-info btn-sm"
                            >
                              Редактировать
                            </NavLink>
                          </Col>
                        </Row>
                        <span className="mt-2">
                          <b>Информация о перечне</b>
                        </span>
                        <hr />
                        <Row>
                          <Col md={4}>Номер:</Col>
                          <Col md={8}>{props.order.number}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Дирекция</Col>
                          <Col md={8}>{props.order?.directorate?.name}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Начало действия:</Col>
                          <Col md={8}>{props.order.starts_affect}</Col>
                        </Row>
                        <hr />
                        <span className="mt-2">
                          <b>Данные обработки файла</b>
                        </span>
                        <hr />
                        <Row>
                          <Col md={4}>Строк в файле:</Col>
                          <Col md={8}>{props.order.file_rows_count}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Строк создано:</Col>
                          <Col md={8}>{props.order.created_rows}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Строк существует:</Col>
                          <Col md={8}>{props.order.founded_rows}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Строк не затронуто:</Col>
                          <Col md={8}>{props.order.not_affected_rows}</Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Производителей определено:</Col>
                          <Col md={8}>
                            {props.order.manufacturers_determined} /{" "}
                            {props.order.file_rows_count}
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col md={4}>Производителей не определено:</Col>
                          <Col md={8}>
                            {props.order.manufacturers_not_determined} /{" "}
                            {props.order.file_rows_count}{" "}
                          </Col>
                        </Row>
                        <hr />
                      </>
                    )}
                  </Card.Body>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="table-full-width table-responsive px-0 overflow-auto">
                  <Card.Header>
                    <Card.Title as="h4">Статистика перечня</Card.Title>
                  </Card.Header>
                  <Card.Body>
                    <div
                      className="overflow-auto"
                      style={{ maxHeight: "900px" }}
                    >
                      {props.isLoading ? (
                        <Spinner />
                      ) : (
                        <>
                          <h5 className="mt-2">
                            <b>Статистика по производителям</b>
                          </h5>
                          <hr />
                          <Table className="table-hover table-sm">
                            <thead>
                              <tr>
                                <th>Наименование</th>
                                <th>Стоимость без НДС</th>
                                <th>Сумма НДС</th>
                                <th>Стоимость с НДС</th>
                              </tr>
                            </thead>
                            <tbody>{statisticManufacturerOptions}</tbody>
                          </Table>

                          <h5 className="mt-2">
                            <b>Статистика по проектам</b>
                          </h5>
                          <hr />
                          <Table className="table-hover table-sm">
                            <thead>
                              <tr>
                                <th>Наименование</th>
                                <th>Стоимость без НДС</th>
                                <th>Сумма НДС</th>
                                <th>Стоимость с НДС</th>
                              </tr>
                            </thead>
                            <tbody>{statisticProjectOptions}</tbody>
                          </Table>
                        </>
                      )}
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Container>

      {/*Контейнер информации о позициях перечня*/}
      <Container fluid>
        <Row>
          <Col className="sm-12">
            <Card>
              <Card.Header className="d-flex justify-content-between">
                <div>
                  <Card.Title as="h4">
                    Позиции перечня технической поддержки
                  </Card.Title>
                  <Dropdown>
                    <Dropdown.Toggle className="btn-fill btn-sm btn-info btn mt-3 mb-0">
                      Действия
                    </Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          props.setShowFileDownloadModal(true);
                          props.handleFileDownload();
                        }}
                      >
                        <Icon
                          path={mdiDownload}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Скачать перечень на ПК
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          setCurrObj({});
                          setCreate(true);
                          props.setShowOrderItemManageModal(true);
                        }}
                      >
                        <Icon
                          path={mdiPlusBoxOutline}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Добавить позицию
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.setShowFileUploadModal(true);
                        }}
                      >
                        <Icon
                          path={mdiFileUploadOutline}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Импорт позиций из файла
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => {
                          props.setShowOrderCopyModal(true);
                        }}
                      >
                        <Icon
                          path={mdiFileMultipleOutline}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Копирование перечня
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => props.setShowOrderDifferenceModal(true)}
                      >
                        <Icon
                          path={mdiFileReplaceOutline}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Сравнение перечней
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={() => setShowHistory(!showHistory)}
                      >
                        <Icon
                          path={mdiHistory}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        {showHistory
                          ? "Скрыть историю изменений"
                          : "Отобразить историю изменений"}
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setObjs(props.manufacturers);
                          setType("manufacturer");
                          props.setShowBulkActionModal(true);
                        }}
                      >
                        <Icon
                          path={mdiFactory}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Массовая установка производителя
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setObjs(props.branches);
                          setType("branch");
                          props.setShowBulkActionModal(true);
                        }}
                      >
                        <Icon path={mdiEarth} size={1} className="ml-0 mr-2" />
                        Массовая установка РЦС
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setObjs(props.categories);
                          setType("category");
                          props.setShowBulkActionModal(true);
                        }}
                      >
                        <Icon
                          path={mdiCashMultiple}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Массовая установка категории
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          setObjs(props.projects);
                          setType("project");
                          props.setShowBulkActionModal(true);
                        }}
                      >
                        <Icon
                          path={mdiBriefcaseCheckOutline}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Массовая установка проекта
                      </Dropdown.Item>
                      <Dropdown.Item
                        onClick={(e) => {
                          e.preventDefault();
                          props.setShowAlertModal(true);
                        }}
                      >
                        <Icon
                          path={mdiCheckAll}
                          size={1}
                          className="ml-0 mr-2"
                        />
                        Массовая проверка позиций
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>

                <div>
                  <select
                    className="form-control form-control-sm"
                    name="pageSize"
                    value={props.count}
                    placeholder="Кол-во элементов"
                    onChange={props.handleCountChange}
                  >
                    {displayCountOptions}
                  </select>
                  <Paginator
                    className="mt-2 text-lg-right"
                    pageSize={props.count}
                    pageNumber={props.number}
                    totalCount={props.totalCount}
                    onClick={props.handleNumberChange}
                  />
                </div>
              </Card.Header>
              <Card.Body
                className="table-full-width table-responsive px-0 overflow-auto"
                style={{ minHeight: "500px" }}
              >
                {props.itemsIsLoading ? (
                  <Spinner />
                ) : (
                  <>
                    <form onSubmit={props.handleSubmit(props.onSubmit)}>
                      <Table className="table-hover table-sm">
                        <thead>
                          <tr>
                            <th>№</th>
                            <th>Модель</th>
                            <th>Серийный номер</th>
                            <th>Категория</th>
                            <th>Стоимость с НДС</th>
                            <th>Начало обслуживания</th>
                            <th>Завершение обслуживания</th>
                            <th>Производитель</th>
                            <th>Проект</th>
                            <th>РЦС</th>
                          </tr>
                          <tr>
                            <td colSpan={2}>
                              <input
                                type="text"
                                placeholder="Модель"
                                className="form-control form-control-sm"
                                {...props.register("name")}
                              />
                            </td>
                            <td colSpan={3}>
                              <input
                                type="text"
                                placeholder="Серийный номер"
                                className="form-control form-control-sm"
                                {...props.register("sn")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Начало действия"
                                className="form-control form-control-sm"
                                {...props.register("starts_affect")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Завершение действия"
                                className="form-control form-control-sm"
                                {...props.register("ends_affect")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Производитель"
                                className="form-control form-control-sm"
                                {...props.register("manufacturer")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="Проект"
                                className="form-control form-control-sm"
                                {...props.register("project")}
                              />
                            </td>
                            <td>
                              <input
                                type="text"
                                placeholder="РЦС"
                                className="form-control form-control-sm"
                                {...props.register("branch")}
                              />
                            </td>
                            <td colSpan={2}>
                              <div className="d-flex justify-content-start">
                                <button className="btn-fill btn btn-info btn-sm">
                                  <Icon path={mdiCheckAll} size={1} />
                                </button>
                                <button
                                  className="btn-fill btn btn-danger btn-sm ml-2"
                                  type="button"
                                  onClick={props.handleFiltersReset}
                                >
                                  <Icon path={mdiTrashCan} size={1} />
                                </button>
                              </div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>{orderItems}</tbody>
                      </Table>
                    </form>
                    <div className="mr-2">
                      <Paginator
                        className="mt-2 text-lg-right"
                        pageSize={props.count}
                        pageNumber={props.number}
                        totalCount={props.totalCount}
                        onClick={props.handleNumberChange}
                      />
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default OrderDetail;
