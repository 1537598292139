import React, { useEffect, useState } from "react";
import OrderManage from "./OrderManage";
import { useNavigate, useParams } from "react-router-dom";
import { connect } from "react-redux";
import { getOrder, saveOrder } from "../../../redux/thunk/OrderThunk";
import { useForm } from "react-hook-form";
import { getDirectorates } from "../../../redux/thunk/MetaThunk";
import { formatDate } from "../../../utils";
import Spinner from "../../../components/Spinners/Spinner";

const OrderManageContainer = (props) => {
  const [order, setOrder] = useState({});
  const [directorates, setDirectorates] = useState([]);
  const params = useParams();
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {},
  });
  const onSubmit = (data) => {
    data.starts_affect = formatDate(data.starts_affect, "y-m-d");
    props
      .saveOrder(data, !params.id)
      .then((res) =>
        res && res.status === 200
          ? navigate(`/orders/${res.data.id}/detail`)
          : null
      );
  };
  useEffect(() => {
    if (params.id) {
      props.getOrder(params.id).then((res) => {
        if (res && res.status === 200) {
          setOrder(res.data);
          reset(res.data);
        }
      });
    }
  }, [params.id]);
  useEffect(() => {
    props
      .getDirectorates()
      .then((res) =>
        setDirectorates(res && res.status === 200 ? res.data.directorates : [])
      );
  }, []);
  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <OrderManage
      order={order}
      register={register}
      control={control}
      handleSubmit={handleSubmit}
      errors={errors}
      onSubmit={onSubmit}
      directorates={directorates}
      saveInProcess={props.saveInProcess}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.order.isLoading,
    saveInProcess: state.order.saveInProcess,
  };
};
export default connect(mapStateToProps, {
  getOrder,
  getDirectorates,
  saveOrder,
})(OrderManageContainer);
