import React, { useEffect, useState } from "react";
import EquipmentDetail from "./EquipmentDetail";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { getEquipment } from "../../../redux/thunk/EquipmentThunk";
import Spinner from "../../../components/Spinners/Spinner";

const EquipmentDetailContainer = (props) => {
  const params = useParams();
  const [equipment, setEquipment] = useState({});
  useEffect(() => {
    props.getEquipment(params.id).then((res) => setEquipment(res.data));
  }, [params.id]);
  if (props.isLoading) {
    return <Spinner />;
  }
  return <EquipmentDetail equipment={equipment} />;
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.equipment.isLoading,
  };
};
export default connect(mapStateToProps, {
  getEquipment,
})(EquipmentDetailContainer);
