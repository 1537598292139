import React from 'react';
import Login from "./Login";
import {connect} from "react-redux";
import {login} from "../../redux/thunk/RootThunk";
import {useForm} from "react-hook-form";
import {useNavigate} from "react-router-dom";
import { setNotification } from 'redux/actions/RootActions';

const LoginContainer = (props) => {
    const navigate = useNavigate()
    const {
        register,
        formState: {errors},
        handleSubmit
    } = useForm({
        defaultValues: {}
    })
    const onSubmit = (data) => {
        props.login(data).then(res => navigate('/orders'))
    }
    return <Login
        authInProcess={props.authInProcess}
        notifications={props.notifications}
        register={register}
        handleSubmit={handleSubmit}
        errors={errors}
        onSubmit={onSubmit}
        setNotification={props.setNotification}
    />
}
const mapStateToProps = state => {
    return {
        authInProcess: state.root.authInProcess,
        notifications: state.root.notifications,
    }
}
export default connect(mapStateToProps, {
    login,
    setNotification
})(LoginContainer);