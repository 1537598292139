import React, { useEffect, useState } from "react";
import Statistic from "./Statistic";
import {
  getStatistic,
  getStatisticFile,
} from "../../redux/thunk/StatisticThunk";
import { connect } from "react-redux";
import Spinner from "../../components/Spinners/Spinner";
import { useForm } from "react-hook-form";
import { setStatisticFilters } from "../../redux/actions/StatisticActions";
import { getBranches, getDirectorates } from "../../redux/thunk/MetaThunk";
import fileDownload from "js-file-download";

const StatisticContainer = (props) => {
  const filters = { ...props.filters };
  const [statistic, setStatistic] = useState({});
  const [directorates, setDirectorates] = useState([]);
  const [branches, setBranches] = useState([]);
  const { register, control, reset, handleSubmit, watch, } = useForm({
    defaultValues: filters,
  });

  const onSubmit = (data) => {
    props.setStatisticFilters(data);
    props
      .getStatistic()
      .then((res) => setStatistic(res && res.status === 200 ? res.data : {}));
  };
  const handleFileDonwload = () => {
    props.getStatisticFile().then((res) => {
      fileDownload(res.data, "Статистика.xlsx");
    });
  };
  useEffect(() => {
    props
      .getStatistic()
      .then((res) => setStatistic(res && res.status === 200 ? res.data : {}));
    props
      .getDirectorates()
      .then((res) =>
        setDirectorates(res && res.status === 200 ? res.data.directorates : [])
      );
    props
      .getBranches()
      .then((res) =>
        setBranches(res && res.status === 200 ? res.data.branches : [])
      );
  }, []);

  useEffect(() => {
    props
      .getBranches(watch("directorate"))
      .then((res) =>
        setBranches(res && res.status === 200 ? res.data.branches : [])
      );
  }, [watch("directorate")]);

  // useEffect(() => {
  //   props
  //     .getStatistic()
  //     .then((res) => setStatistic(res && res.status === 200 ? res.data : {}));
  // }, [watch("type")]);

  if (props.isLoading) {
    return <Spinner />;
  }
  return (
    <Statistic
      register={register}
      watch={watch}
      control={control}
      handleSubmit={handleSubmit}
      onSubmit={onSubmit}
      handleFileDonwload={handleFileDonwload}
      statistic={statistic}
      directorates={directorates}
      branches={branches}
      fileCreateInProcess={props.fileCreateInProcess}
      type={filters.type}
    />
  );
};
const mapStateToProps = (state) => {
  return {
    isLoading: state.statistic.isLoading,
    filters: state.statistic.filters,
    fileCreateInProcess: state.statistic.fileCreateInProcess,
  };
};
export default connect(mapStateToProps, {
  getStatistic,
  getDirectorates,
  getBranches,
  getStatisticFile,
  setStatisticFilters,
})(StatisticContainer);
