import React from "react";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import Select from "react-select";
import { Controller } from "react-hook-form";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Line } from "react-chartjs-2";
import Icon from "@mdi/react";
import { mdiDownload, mdiCheckAll } from "@mdi/js";
import { months } from "utils";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const LineChart = (props) => {
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: true,
      },
      resize: [600, 600],
    },
  };

  const data = {
    labels: props.range,
    datasets: [
      {
        label: "Цена с НДС",
        data: props.data,
        borderColor: "rgb(255, 99, 132)",
        backgroundColor: "rgba(255, 99, 132, 0.5)",
      },
    ],
  };
  return <Line options={options} data={data} />;
};
const StatisticTableHeader = (props) => {
  const firstHeaderRow = props?.columns?.map((c) => (
    <td colSpan={4} className="text-center align-middle">
      <b>{c}</b>
    </td>
  ));
  const secondHeaderRow = props?.columns?.map((c) => (
    <>
      <td className="text-center">
        <small>
          <b>Стоимость без НДС</b>
        </small>
      </td>
      <td className="text-center">
        <small>
          <b>Сумма НДС</b>
        </small>
      </td>
      <td className="text-center">
        <small>
          <b>Стоимость с НДС</b>
        </small>
      </td>
      <td className="text-center">
        <small>
          <b>Количество</b>
        </small>
      </td>
    </>
  ));
  return (
    <thead>
      <tr>
        <td rowSpan={2} className="text-center">
          <small>
            <b>№</b>
          </small>
        </td>
        <td rowSpan={2} className="text-center">
          <small>
            <b>{props.attr}</b>
          </small>
        </td>
        {firstHeaderRow}
      </tr>
      <tr>{secondHeaderRow}</tr>
    </thead>
  );
};
const StatisticTableRow = (props) => {
  const columns = props.statistic.map((s) => (
    <>
      <td>
        <small>{Number(s.price_without_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(s.tax_value).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(s.price_with_tax).toLocaleString()}</small>
      </td>
      <td>
        <small>{Number(s.count).toLocaleString()}</small>
      </td>
    </>
  ));
  return (
    <tr>
      <td>
        <small>{props.number}</small>
      </td>
      <td>
        <small>{props.name}</small>
      </td>
      {columns}
    </tr>
  );
};
const Statistic = (props) => {
  const manufacturerRows = props.statistic?.manufacturers?.map((m, index) => (
    <StatisticTableRow key={index} number={index + 1} {...m} />
  ));
  const projectRows = props.statistic?.projects?.map((p, index) => (
    <StatisticTableRow key={index} number={index + 1} {...p} />
  ));
  const years = [];
  for (let i = 1; i <= 10; i++) {
    years.push(2020 + i);
  }
  const yearsOptions = years.map((y, index) => (
    <option key={index} value={y}>
      {y}
    </option>
  ));
  const directoratesOptions = props.directorates.map((d) => ({
    value: d.id,
    label: d.name,
  }));
  const branchesOptions = props.branches.map((b) => ({
    value: b.id,
    label: b.name,
  }));
  const typesOptions = [
    { value: "per_year", label: "По годам" },
    { value: "per_month", label: "По месяцам" },
  ].map((t, index) => (
    <option key={index} value={t.value}>
      {t.label}
    </option>
  ));
  return (
    <Container fluid>
      <Row>
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">Фильтры</Card.Title>
            </Card.Header>
            <Card.Body>
              <form onSubmit={props.handleSubmit(props.onSubmit)}>
                <div className="form-group">
                  <Row>
                    <Col md={1}>
                      <small>Начало</small>
                    </Col>
                    <Col md={3}>
                      <select
                        className="form-control form-control-sm"
                        {...props.register("start")}
                      >
                        {yearsOptions}
                      </select>
                    </Col>
                    <Col md={1}>
                      <small>Дирекция</small>
                    </Col>
                    <Col md={3}>
                      <Controller
                        name="directorate"
                        control={props.control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              options={directoratesOptions}
                              isClearable={true}
                              defaultValue={null}
                              placeholder="Дирекция"
                              value={
                                directoratesOptions.find(
                                  (p) => p.value === value
                                ) || null
                              }
                              onChange={(e) => onChange(e ? e.value : null)}
                            />
                          );
                        }}
                      />
                    </Col>
                    <Col md={1}>
                      <small>Тип подсчета</small>
                    </Col>
                    <Col md={3}>
                      <select
                        className="form-control form-control-sm"
                        {...props.register("type")}
                      >
                        {typesOptions}
                      </select>
                    </Col>
                  </Row>
                  <hr />
                  <Row>
                    {props.watch("type") === "per_year" && (
                      <>
                        <Col md={1}>
                          <small>Завершение</small>
                        </Col>
                        <Col md={3}>
                          <select
                            className="form-control form-control-sm"
                            {...props.register("end")}
                          >
                            {yearsOptions}
                          </select>
                        </Col>
                      </>
                    )}
                    <Col md={1}>
                      <small>РЦС</small>
                    </Col>
                    <Col md={3}>
                      <Controller
                        name="branch"
                        control={props.control}
                        render={({ field: { onChange, value } }) => {
                          return (
                            <Select
                              options={branchesOptions}
                              isClearable={true}
                              defaultValue={null}
                              placeholder="РЦС"
                              value={
                                branchesOptions.find(
                                  (p) => p.value === value
                                ) || null
                              }
                              onChange={(e) => onChange(e ? e.value : null)}
                            />
                          );
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col className="d-flex justify-content-start">
                      <button className="btn-fill btn btn-sm btn-info mt-3 mr-2">
                        Применить фильтры
                        <Icon path={mdiCheckAll} size={1} className="ml-2" />
                      </button>
                      <button
                        className="btn-fill btn btn-sm btn-info mt-3"
                        onClick={(e) => {
                          e.preventDefault();
                          props.handleFileDonwload();
                        }}
                      >
                        {props.fileCreateInProcess ? (
                          <div className="spinner-border spinner-border-sm text-white" />
                        ) : (
                          <>
                            Сохранить на ПК
                            <Icon
                              path={mdiDownload}
                              size={1}
                              className="ml-2"
                            />
                          </>
                        )}
                      </button>
                    </Col>
                  </Row>
                </div>
              </form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      {props.type === "per_year" ? (
        <>
          <Row className="mt-3">
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Статистика по производителям</Card.Title>
                </Card.Header>
                <Card.Body className="py-1 overflow-auto">
                  <Table className="table-sm table-hover table-bordered  mt-3">
                    <StatisticTableHeader
                      columns={props.statistic.range}
                      attr={"Произвоитель"}
                    />
                    <tbody>{manufacturerRows}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Статистика по проектам</Card.Title>
                </Card.Header>
                <Card.Body className="py-1 overflow-auto">
                  <Table className="table-sm table-hover table-bordered mt-3">
                    <StatisticTableHeader
                      columns={props.statistic.range}
                      attr={"Проект"}
                    />
                    <tbody>{projectRows}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      ) : (
        <>
          <Row className="mt-3">
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Статистика по производителям</Card.Title>
                </Card.Header>
                <Card.Body className="py-1 overflow-auto">
                  <Table className="table-sm table-hover table-bordered  mt-3">
                    <StatisticTableHeader
                      columns={Object.values(months)}
                      attr={"Произвоитель"}
                    />
                    <tbody>{manufacturerRows}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
          <Row className="mt-3">
            <Col md={12}>
              <Card>
                <Card.Header>
                  <Card.Title as="h4">Статистика по проектам</Card.Title>
                </Card.Header>
                <Card.Body className="py-1 overflow-auto">
                  <Table className="table-sm table-hover table-bordered  mt-3">
                    <StatisticTableHeader
                      columns={Object.values(months)}
                      attr={"Проект"}
                    />
                    <tbody>{projectRows}</tbody>
                  </Table>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </>
      )}
      <Row className="mt-3">
        <Col md={12}>
          <Card>
            <Card.Header>
              <Card.Title as="h4">
                График итоговой стоимости с НДС за период
              </Card.Title>
            </Card.Header>
            <Card.Body className="py-1 overflow-auto">
              <Col md={6} className="d-flex justify-content-center">
                <LineChart
                  data={props.statistic.total_sum}
                  range={props.statistic.range}
                />
              </Col>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Statistic;
